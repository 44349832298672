import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-mis-maquinas',
  templateUrl: './mis-maquinas.component.html',
  styleUrls: ['./mis-maquinas.component.scss']
})
export class MisMaquinasComponent implements OnInit {
  public maquinas: Array<any>;

  constructor(private _api: ApiCallService, private _route: Router,) {

  }

  ngOnInit(): void {

    var arregloIMgen = [];

    //arregloIMgen.push({imagen: "https://www.taglermaq.cl/wp-content/uploads/2021/04/new-maq1.png"});
    //arregloIMgen.push({imagen: "https://www.taglermaq.cl/wp-content/themes/taglermaq/img/maq.png"});
    //arregloIMgen.push({imagen: "https://www.taglermaq.cl/wp-content/uploads/2021/04/new-mac2.png"});
    arregloIMgen.push({ imagen: GlobalService.imageNotFound });
    console.log(arregloIMgen);

    this._api.TarjetasDeEquipo().subscribe(res => {
       console.log(res);
      res.forEach(element => {
        if (!element.U_URL_Imagen_SKU) {


          var alAzar = parseInt((Math.random() * arregloIMgen.length).toString(), 0);

          element.U_URL_Imagen_SKU = arregloIMgen[alAzar].imagen;
        }
      });
      this.maquinas = res;
    })
    console.log("M",this.maquinas);
  }

  cargarDatos(item) {
    // console.log(item.itemCode);
    // console.log(item.internalSN);
    this._route.navigate(["/historico", item.itemCode, item.internalSN])
  }


  verPdf(item) {
    if (item.U_INDS_IT_PDF_URL) {
      console.log(item);
      this._route.navigate(["/verPdf", item.U_INDS_IT_PDF_URL])
    }
    else {
      Swal.fire("Maquina no cuenta con ficha tecnica");
    }
  }




}


