import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'TaglerPS';
  constructor() { }

  ngOnInit(): void {


    

    AOS.init({
        offset: 10,
        duration: 1000
    });
  }




  
}
