import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  Perfil: any;
  constructor(
    private _cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.Perfil = this._cookieService.get('perfil');

    /* Perfil: Gold, Platinum, Silver, Global */
  }

}
