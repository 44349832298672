import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // public static urlAPI = "https://localhost:44382/";
  // public static urlAPI = "http://127.0.0.1:8080/";
  public static urlAPI = "https://taglerpsapi.indeone.app/";
  //public static urlAPI = "https://remoto.indesad.cl:8091/";
  public static imageNotFound = "https://www.taglermaq.cl/wp-content/uploads/QR/Equipos/sin_imagen.jpg";
  constructor() { }
}
