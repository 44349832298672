import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiCallService } from '../api-call.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-cuenta-personal',
  templateUrl: './cuenta-personal.component.html',
  styleUrls: ['./cuenta-personal.component.scss']
})
export class CuentaPersonalComponent implements OnInit {

  constructor(
    private _api: ApiCallService,
  ) { }

  usuario:any;
  facturacion:any;
  despacho:any;
  ngOnInit(): void {
    this.usuario = [];
    this._api.getLogin().subscribe(res => {
      this.usuario = res;
    });


    this.facturacion = [];
    this.despacho = [];
    this._api.getDireccion().subscribe(res => {
      res.forEach(element => {
        //console.log(element);
        //factura
        if (element.AdresType == "B") {
          this.facturacion.push(element);
        }
        //despacho
        else if (element.AdresType == "S") {
          this.despacho.push(element);
        }
      });
      
    });

  }

}
