import { Component, OnInit} from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { ActivatedRoute, Router } from '@angular/router'
import { DatePipe } from '@angular/common'
import Swal from 'sweetalert2';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss'],
  providers: [DatePipe],
})
export class HistoricoComponent implements OnInit {
  public token = '65cad703-85a1-45ec-8d3e-d03b7824f1a0';
  public objMaquina: any = {
    "item_code": "AS105246",
    "dscription_code": "",
    "url_image_code": "",
    "brand": "",
    "url_brand_image": "",
    "serie": "",
    "model": "",
    "services_call": [
      {
        "number": 0,
        "activities": [
          {
            "number": 0,
            "date": "",
            "description": "",
            "attachments": []
          }
        ]
      },
    ]
  };
  public historico: any;
  public ItemCode;
  public MnfSerial;
  constructor(
    private _api: ApiCallService,
    private route: ActivatedRoute,
    private _router: Router,
    public datepipe: DatePipe) {

  }

  ngOnInit(): void {

    this.ItemCode = this.route.snapshot.paramMap.get("ItemCode");
    this.MnfSerial = this.route.snapshot.paramMap.get("InternalSN");

    try {
      this._api.HistoricoMaquina(this.ItemCode, this.MnfSerial).subscribe(res => {

        this.objMaquina = res.result[0].cards[0];
        if (!this.objMaquina.url_image_code)
        {
          this.objMaquina.url_image_code = GlobalService.imageNotFound;
        }
        console.log(this.objMaquina);

        this.historico = this.objMaquina.services_call;

        this.historico.forEach(element => {
          console.log(element);
        });

        console.log("H",this.historico);

        // this.historico.forEach(element => {
        //   var aux = element.activities[0].date;
        //   console.log(this.datepipe.transform(aux,'yyyy-MM-dd'));
        //   element.activities[0].date = aux;
        // });

      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      })
    }



  }


  AbrirPDF(item) {
    //console.log(item);

    if (item.length > 0) {
      if (item[0].ur_file) {
        this._router.navigate(["/verPdf", item[0].ur_file]);
      }
      else {
        Swal.fire("Actividad sin adjunto");
      }
    }
    else {
      Swal.fire("Actividad sin adjunto");
    }
  }
  volverHistorico()
  {
    window.history.back();
  }

}
