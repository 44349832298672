<div class="header-coti" data-aos="fade-in" data-aos-delay="100">
  <div class="container">
    <div class="content-block-header">
      <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
      <div class="wrapper-header-coti">
        <a class="remover-line" href="/menu">
          <div class="burger"><span class="class-home icon-home-01"></span></div>
        </a>
        <div class="btn-view-filters" (click)="AbrirFiltro();">Filtros</div>

        <div class="menu" id="menu" (click)="AbreMenu();">
          <div class="linea-1"></div>
          <div class="linea-2"></div>
          <div class="linea-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="panel-left-emergent">
  <div class="wrapper-list-left">
    <div id="left-animation" class="wrapper--fechas">
      <label class="color-title-fecha" for="start">
        <span class="margen-fech icon-calendar"></span> Fecha Inicio</label>
      <input id="ver-fecha" type="date" [(ngModel)]="inicio">

      <label class="color-title-fecha" for="start">
        <span class="margen-fech icon-calendar"></span> Fecha Final</label>
      <input id="ver-fecha" type="date" [(ngModel)]="termino">

      <!-- Seleccion multiple -->
      <h4 class="title--estados">MÁQUINAS</h4>
      <div class="wrapper-selection-multiply">
        <!--         <select multiple data-placeholder="Seleccionar máquinas" [(ngModel)]="maquinas">
          <option>Inyectora PI 21 M</option>
          <option *ngFor="let item of maquinas">{{item.Maquina_descripcion}}</option>
        </select> -->
        <!--prueba select -->
        <!--         <select  data-placeholder="Seleccionar máquinas" [(ngModel)]="maquinas">
          <option *ngFor="let item of MaquinaUnica" [value]="item.Maquina">{{item.Maquina_descripcion}}</option>
        </select>-->
        <ng-multiselect-dropdown [placeholder]="'Seleccionar máquinas'" [settings]="dropdownSettings"
          [data]="dropdownList" [(ngModel)]="selectedItems" (ngModelChange)="cambio($event)">
        </ng-multiselect-dropdown>
      </div>

      <!--       <h4 class="title--estados">ESTADO</h4>
      <div class="wrapper-selection-multiply">
        <div class="select-box ">
          <div class="select-box__current" tabindex="1">
            <div class="select-box__value">
              <input class="select-box__input" type="radio" id="0" value="1" name="Ben" checked="checked"
                [(ngModel)]="estado" />
              <p class="select-box__input-text">SI</p>
            </div>
            <div class="select-box__value">
              <input class="select-box__input" type="radio" id="1" value="2" name="Ben" [(ngModel)]="estado" />
              <p class="select-box__input-text">NO</p>
            </div>
            <div class="select-box__value">
              <input class="select-box__input" type="radio" id="2" value="3" name="Ben" [(ngModel)]="estado" />
              <p class="select-box__input-text">TODOS</p>
            </div>
            <img class="select-box__icon" src="/assets/img/down.svg" alt="Arrow Icon" aria-hidden="true" />
          </div>
          <ul class="select-box__list">
            <li>
              <label class="select-box__option" for="0" aria-hidden="aria-hidden">SI</label>
            </li>
            <li>
              <label class="select-box__option" for="1" aria-hidden="aria-hidden">NO</label>
            </li>
            <li>
              <label class="select-box__option" for="2" aria-hidden="aria-hidden">TODOS</label>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="wrapper-selection-multiply text-right">
        <div class="btn-view-filters" (click)="filtro();">Filtrar</div>
        <div class="btn-view-filters-red" (click)="quitarfiltro();">Quitar Filtro</div>
      </div>

    </div>
  </div>
</div>


<div class="wrapper-mask">
  <div class="panel-right-emergent">
    <div class="wrapper-list-right">
      <ul id="ver-animation" class="list-right">
        <a id="CuentasClaras" (click)="cambioElemento('CuentasClaras','');" class="txt-hover">
          <li class="li-list">
            <span class="paint1 icon-derecha icon-factura"></span>Facturas emitidas
          </li>
        </a>
        <a id="FacturasPorVencer" (click)="cambioElemento('FacturasPorVencer','');" class="txt-hover">
          <li class="li-list">
            <span class="paint2 icon-derecha icon-factura"></span>Facturas por vencer
          </li>
        </a>
        <a id="FacturasVencidas" (click)="cambioElemento('FacturasVencidas','');" class="txt-hover">
          <li class="li-list">
            <span class="paint3 icon-derecha icon-factura"></span>Facturas vencidas
          </li>
        </a>
        <a id="NotasDeCredito" (click)="cambioElemento('NotasDeCredito','');" class="txt-hover">
          <li class="li-list">
            <span class="paint4 icon-derecha icon-factura"></span>Notas de crédito
          </li>
        </a>
        <a id="InversionesAnioActual" (click)="cambioElemento('InversionesAnioActual','');" class="txt-hover">
          <li class="li-list">
            <span class="paint5 icon-derecha icon-inversion"></span>Inversión Año {{this.anioActual}}
          </li>
        </a>
        <a id="InversionesHistorico" (click)="cambioElemento('InversionesHistorico','');" class="txt-hover">
          <li class="li-list">
            <span class="paint5 icon-derecha icon-inversion"></span>Inversión Histórica
          </li>
        </a>
      </ul>
    </div>
  </div>
</div>


<div *ngIf="this.paginaActual=='CuentasClaras'">
  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color1">FACTURAS EMITIDAS</h1>
    </div>
    <div class="scroll-eje-x">
      <table class="table ancho-mobile">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Fecha</th>
            <!-- <th scope="col">Maquina</th> -->
            <!-- <th scope="col">Nº S</th> -->
            <th scope="col">LL/S</th>
            <th scope="col">Documento</th>
            <!-- <th scope="col">Descripción</th> -->
            <th class="alinear-derecha" scope="col">Repuestos</th>
            <th class="alinear-derecha" scope="col">Mano de obra</th>
            <th class="alinear-derecha" scope="col">Despacho</th>
            <th class="alinear-derecha" scope="col">Total</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of emitidas; let i=index">
          <tr>
            <td scope="row">{{item.DocDate}}</td>
            <!-- <td class="link-element"  (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td> -->
            <!-- <td class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
            <td class="link-element"  (click)="AbrirInformeTecnico(item);">{{item.ID_LLAMADA}}</td>
            <td class="link-element" (click)="AbrirDTE(item);">{{item.ORIGEN_ABREVIADO}}-{{item.FolioNum}}</td>
            <!-- <td><span class="btn-ver" (click)="verObservaciones(item.Comments);">VER</span></td> -->
            <td align="right">{{item.REPUESTOS}}</td>
            <td align="right">{{item.MANOOBRA}}</td>
            <td align="right">{{item.DESPACHO}}</td>
            <td align="right">{{item.DocTotal}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="this.paginaActual=='FacturasPorVencer'">
  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles">
      <h1 class="title-start-color2" data-aos="fade-up" data-aos-delay="100">FACTURAS POR VENCER</h1>
    </div>
    <div class="scroll-eje-x">
      <table class="table ancho-mobile">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Fecha</th>
            <!-- <th scope="col">Maquina</th> -->
            <!-- <th scope="col">Nº S</th> -->
            <th scope="col">LL/S</th>
            <th scope="col">Documento</th>
            <!-- <th scope="col">Descripción</th> -->
            <th class="alinear-derecha" scope="col">Repuestos</th>
            <th class="alinear-derecha" scope="col">Mano de obra</th>
            <th class="alinear-derecha" scope="col">Despacho</th>
            <th class="alinear-derecha" scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of porVencer; let i=index">
            <td scope="row">{{item.DocDate}}</td>
            <!-- <td class="link-element"  (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td> -->
            <!-- <td class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
            <td class="link-element"  (click)="AbrirInformeTecnico(item);">{{item.ID_LLAMADA}}</td>
            <td class="link-element" (click)="AbrirDTE(item);">{{item.ORIGEN_ABREVIADO}}-{{item.FolioNum}}</td>
            <!-- <td><span class="btn-ver" (click)="verObservaciones(item.Comments);">VER</span></td> -->
            <td align="right">{{item.REPUESTOS}}</td>
            <td align="right">{{item.MANOOBRA}}</td>
            <td align="right">{{item.DESPACHO}}</td>
            <td align="right">{{item.DocTotal}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="this.paginaActual=='FacturasVencidas'">
  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles">
      <h1 class="title-start-color3" data-aos="fade-up" data-aos-delay="100">FACTURAS VENCIDAS</h1>
    </div>
    <div class="scroll-eje-x">
      <table class="table ancho-mobile">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Fecha</th>
            <!-- <th scope="col">Maquina</th> -->
            <!-- <th scope="col">Nº S</th> -->
            <th scope="col">LL/S</th>
            <th scope="col">Documento</th>
            <!-- <th scope="col">Descripción</th> -->
            <th class="alinear-derecha" scope="col">Repuestos</th>
            <th class="alinear-derecha" scope="col">Mano de obra</th>
            <th class="alinear-derecha" scope="col">Despacho</th>
            <th class="alinear-derecha" scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Vencidas; let i=index">
            <td scope="row">{{item.DocDate}}</td>
            <!-- <td class="link-element"  (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td> -->
            <!-- <td class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
            <td class="link-element"  (click)="AbrirInformeTecnico(item);">{{item.ID_LLAMADA}}</td>
            <td class="link-element" (click)="AbrirDTE(item);">{{item.ORIGEN_ABREVIADO}}-{{item.FolioNum}}</td>
            <!-- <td><span class="btn-ver" (click)="verObservaciones(item.Comments);">VER</span></td> -->
            <td align="right">{{item.REPUESTOS}}</td>
            <td align="right">{{item.MANOOBRA}}</td>
            <td align="right">{{item.DESPACHO}}</td>
            <td align="right">{{item.DocTotal}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<div *ngIf="this.paginaActual=='NotasDeCredito'">
  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color4">NOTAS DE CRÉDITO</h1>
    </div>
    <div class="scroll-eje-x">
      <table class="table ancho-mobile">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Fecha</th>
            <!-- <th scope="col">Maquina</th> -->
            <!-- <th scope="col">Nº S</th> -->
            <th scope="col">LL/S</th>
            <th scope="col">Documento</th>
            <!-- <th scope="col">Descripción</th> -->
            <th class="alinear-derecha" scope="col">Repuestos</th>
            <th class="alinear-derecha" scope="col">Mano de obra</th>
            <th class="alinear-derecha" scope="col">Despacho</th>
            <th class="alinear-derecha" scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of N_Credito; let i=index">
            <td scope="row">{{item.DocDate}}</td>
            <!-- <td class="link-element"  (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td> -->
            <!-- <td class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
            <td class="link-element"  (click)="AbrirInformeTecnico(item);">{{item.ID_LLAMADA}}</td>
            <td class="link-element" (click)="AbrirDTE(item);">{{item.ORIGEN_ABREVIADO}}-{{item.FolioNum}}</td>
            <!-- <td><span class="btn-ver" (click)="verObservaciones(item.Comments);">VER</span></td> -->
            <td align="right">{{item.REPUESTOS}}</td>
            <td align="right">{{item.MANOOBRA}}</td>
            <td align="right">{{item.DESPACHO}}</td>
            <td align="right">{{item.DocTotal}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="this.paginaActual=='InversionesAnioActual'">
  <div class="content-overflow">
    <div class="wrapper-graficos">

        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
          [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="formatBarColor"
          class="content-graficos" id="myChart" (chartClick)="chartClicked($event)" >
        </canvas>
<!-- (chartHover)="chartHovered($event)" -->

    </div>

  </div>
</div>

<div *ngIf="this.paginaActual=='InversionesHistorico'">
  <div class="content-overflow">
    <div class="wrapper-graficos">

      <canvas baseChart [datasets]="barChartDataH" [labels]="barChartLabelsH" [options]="barChartOptions"
        [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="formatBarColor"
        class="content-graficos" id="myChart2">
      </canvas>

    </div>
    <button id="cerrar" (click)="btnCerrar();"><span class="cerrar-modal icon-cerrar"></span></button>
  </div>
</div>


<app-btn-circle></app-btn-circle>
