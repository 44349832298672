<div class="header-coti" data-aos="fade-in" data-aos-delay="100">
  <div class="container">
    <div class="content-block-header" data-aos="fade-in" data-aos-delay="200">
      <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
      <div class="wrapper-header-coti">
        <a class="remover-line" href="/menu">
          <div class="burger"><span class="class-home icon-home-01"></span></div>
        </a>

        <div *ngIf="this.paginaActual!='SolicitudDeServicio'">
          <div class="btn-view-filters" (click)="AbrirFiltro();">Filtros</div>
        </div>
        <div class="menu" id="menu" (click)="AbreMenu();" >
          <div class="linea-1"></div>
          <div class="linea-2"></div>
          <div class="linea-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="panel-left-emergent">
  <div class="wrapper-list-left">
    <div id="left-animation" class="wrapper--fechas">
      <label class="color-title-fecha" for="start">
        <span class="margen-fech icon-calendar"></span> Fecha Inicio</label>
      <input id="ver-fecha" type="date" [(ngModel)]="inicio">

      <label class="color-title-fecha" for="start">
        <span class="margen-fech icon-calendar"></span> Fecha Final</label>
      <input id="ver-fecha" type="date" [(ngModel)]="termino">

      <!-- Seleccion multiple -->
      <h4 class="title--estados">MÁQUINAS</h4>
      <div class="wrapper-selection-multiply">
        <ng-multiselect-dropdown [placeholder]="'Seleccionar máquinas'" [settings]="dropdownSettings"
          [data]="dropdownList" [(ngModel)]="selectedItems">
        </ng-multiselect-dropdown>
      </div>


<!--       <h4 class="title--estados">ESTADO</h4>
      <div class="select-box">
        <div class="select-box__current" tabindex="1">
          <div class="select-box__value">
            <input class="select-box__input" type="radio" id="0" value="1" name="Ben" checked="checked"  [(ngModel)]="estado" />
            <p class="select-box__input-text">Si</p>
          </div>
          <div class="select-box__value">
            <input class="select-box__input" type="radio" id="1" value="2" name="Ben"  [(ngModel)]="estado" />
            <p class="select-box__input-text">No</p>
          </div>
          <div class="select-box__value">
            <input class="select-box__input" type="radio" id="2" value="3" name="Ben"  [(ngModel)]="estado"  />
            <p class="select-box__input-text">TODOS</p>
          </div>
          <img class="select-box__icon" src="/assets/img/down.svg" alt="Arrow Icon" aria-hidden="true" />
        </div>
        <ul class="select-box__list">
          <li>
            <label class="select-box__option" for="0" aria-hidden="aria-hidden">Si</label>
          </li>
          <li>
            <label class="select-box__option" for="1" aria-hidden="aria-hidden">No</label>
          </li>
          <li>
            <label class="select-box__option" for="2" aria-hidden="aria-hidden">TODOS</label>
          </li>
        </ul>
      </div> -->
      <div class="wrapper-selection-multiply">
      </div>
      <div class="wrapper-selection-multiply text-right">
        <div class="btn-view-filters" (click)="filtro();">Filtrar</div>
        <div class="btn-view-filters-red" (click)="quitarfiltro();">Quitar Filtro</div>
      </div>

    </div>
  </div>
</div>






<div class="panel-left-emergent">
  <div class="wrapper-list-left">
    <ul class="list-left">
      <li class="li-list"><a class="txt-hover" href="#"></a>Fecha Inicio</li>
      <li class="li-list"><a class="txt-hover" href="#"></a>Fecha Final</li>
      <li class="li-list"><a class="txt-hover" href="#"></a>Máquina</li>
      <li class="li-list"><a class="txt-hover" href="#"></a>Tipo Cotización</li>
      <li class="li-list"><a class="txt-hover" href="#"></a>Estado</li>
    </ul>
  </div>
</div>

<div class="wrapper-mask">
  <div class="panel-right-emergent">
    <div class="wrapper-list-right">
      <ul id="ver-animation" class="list-right">
        <a class="txt-hover" (click)="cambioElemento('SolicitudDeServicio');" class="txt-hover">
          <li class="li-list"><span class="paint1 icon-derecha icon-servicios"></span>Solicitud de Servicio</li>
        </a>
        <a class="txt-hover" (click)="cambioElemento('ServiciosPendientes');" class="txt-hover">
          <li class="li-list"><span class="paint6 icon-derecha icon-servicios"></span>Servicios Pendientes</li>
        </a>
        <a class="txt-hover" (click)="cambioElemento('ServiciosEnCurso');" class="txt-hover">
          <li class="li-list"><span class="paint7 icon-derecha icon-servicios"></span>Servicios en Curso</li>
        </a>
        <a class="txt-hover" (click)="cambioElemento('HistoricoSolicitudes');" class="txt-hover">
          <li class="li-list"><span class="paint8 icon-derecha icon-history"></span>Histórico Solicitudes</li>
        </a>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="this.paginaActual=='SolicitudDeServicio'">



  <div class="container" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper--check--servicios">



      <form id="myForm" data-aos="fade-up" data-aos-delay="100" [formGroup]="loginForm" novalidate>
        <div class="content--check-servicios">
          <div class="content-seleccion">
            <h3 class="title-check">Seleccione un servicio de asistencia técnica:</h3>
          </div>
          <ul class="margen--opcion">
            <li class="marcar-opcion">
              <input type="radio" id="opcion-1" name="selector" value="D3D" formControlName="tipoAsistencia">
              <label for="opcion-1">Dentro de los próximos 3 días</label>
              <div class="check"></div>
            </li>
            <li class="marcar-opcion">
              <input type="radio" id="opcion-2" name="selector" value="D7D" formControlName="tipoAsistencia">
              <label for="opcion-2">Dentro de los próximos 7 días</label>
              <div class="check">
                <div class="inside"></div>
              </div>
            </li>
            <li class="marcar-opcion">
              <input type="radio" id="opcion-3" name="selector" value="D2S" formControlName="tipoAsistencia">
              <label for="opcion-3">Dentro de los próximas 2 semanas</label>
              <div class="check">
                <div class="inside"></div>
              </div>
            </li>
            <li class="marcar-opcion">
              <input type="radio" id="opcion-4" name="selector" value="D30D" formControlName="tipoAsistencia">
              <label for="opcion-4">Dentro de los próximas 30 días</label>
              <div class="check">
                <div class="inside"></div>
              </div>
            </li>
          </ul>
        </div>

        <button class="btn-check" (click)="btnBuscarMaquina();" data-aos="fade-up" data-aos-delay="200">SELECCIONAR
          MÁQUINA</button>

          <div id="divMaquinaSeleccionada" class="wrapper-selection-machine" data-aos="fade-up" data-aos-delay="300"
            style="display: none;" >
            <div *ngFor="let item of maquinas; let i=index">
              <div class="content-selection-machine" *ngIf="item.insID==this.maquinasSelecionada">

                <div class="circle-machine">
                  <img class="img-selection-machine" src="{{item.U_URL_Imagen_SKU}}" alt="">
                </div>
                <div class="title-selection-machine">
                  <h2 class="title1">{{item.ItemName}}</h2>
                  <h3 class="title2">{{item.Marca}}</h3>
                  <hr class="line-hr">
                  <h4 class="title3">Serie: {{item.internalSN}}</h4>
                  <h4 class="title3">Fecha de Instalación {{item.U_INDS_IT_ANIO}}</h4>

                </div>

              </div>
            </div>
          </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1">Ingrese Solicitud</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" formControlName="Solicitud"></textarea>


          <button class="btn-check"  data-aos="fade-up" data-aos-delay="200" (click)="generarSolicitud();">Generar solicitud</button>
        </div>
      </form>

      <!--

              <div class="wrapper--message" data-aos="fade-up" data-aos-delay="300">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>NOTA:</strong> Valor de la tarifa del servicio técnico (hora hombre)
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="wrapper-table-horario" data-aos="fade-up" data-aos-delay="400">
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">Horario hábil, día hábil:</th>
              <td>1,5 UF</td>
            </tr>
            <tr>
              <th scope="row">Sábado:</th>
              <td>1,5 UF x 50% recargo</td>
            </tr>
            <tr>
              <th scope="row">Domingo:</th>
              <td>1,5 UF x 100% recargo</td>
            </tr>
          </tbody>
        </table>
      </div>

      -->

    </div>
  </div>

  <!--RODRIGO QUITA ESTA MIERDA!!!!!-->
  <br><br><br><br><br><br>


  <div class="wrapper-overlay">
    <div class="content-overlay" id="ver-circle">
      <input class="search" type="search" id="buscar" name="buscar" placeholder="Buscar Maquina..."  (keypress)="BuscarMaquina()">
      <div class="search-element"></div>
      <div class="content--maquinas--overlay" *ngFor="let item of maquinas; let i=index">
        <div class="box-maquinas">
          <img class="img-ajustar" src="{{item.U_URL_Imagen_SKU}}" alt="">
        </div>
        <div class="box-titles-maquinas">
          <ul class="lista-maquinas">
            <li class="titulo-maquinas">{{item.ItemName}}</li>
            <li class="color-maquinas">{{item.Marca}}</li>
            <li>Serie: {{item.internalSN}}</li>
            <li>Fecha de Instalación {{item.U_INDS_IT_ANIO}}</li>
            <button class="btn-seleccionar" (click)="SeleccionMaquina(item);">seleccionar</button>
          </ul>
        </div>
      </div>

      <button id="cerrar" (click)="btnCerrarBuscarMaquina();" ><span class="cerrar-modal icon-cerrar"></span></button>
    </div>
  </div>
</div>
<!-- vista ServiciosPendientes -->
<div *ngIf="this.paginaActual=='ServiciosPendientes'">
  <div class="container contenedor-tabla" data-aos="fade-up" data-aos-delay="300">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color5">SERVICIOS PENDIENTES</h1>
    </div>
    <div class="container scroll-eje-x">
      <table class="table ancho-mobile2">
        <thead class="posicion-top">
          <tr>

            <th scope="col">Nº LL/S</th>
            <th scope="col">Fecha de solicitud</th>
            <th scope="col">Fecha asistencia técnica</th>
            <th scope="col">Máquina</th>
            <th scope="col">Serie</th>

            <th scope="col">Solicitud</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of serviciosPendientes; let i=index">
            <td scope="row">{{item.callID}}</td>
            <td>{{item.F_SOLICITUD}}</td>
            <td>{{item.F_ASISTENCIA_TECNICA}}</td>
            <td >{{item.ItemName}}</td>
            <td>{{item.internalSN}}</td>
            <td>{{item.DESDE_SOLICITUD}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


</div>

<div *ngIf="this.paginaActual=='ServiciosEnCurso'">

  <div class="container contenedor-tabla" data-aos="fade-up" data-aos-delay="300">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color6">SERVICIOS EN CURSO</h1>
    </div>
    <div class="container scroll-eje-x">
      <table class="table ancho-mobile2">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Nº LL/S</th>
            <th scope="col">Fecha de solicitud</th>
            <th scope="col">Fecha de asistencia</th>
            <th scope="col">Máquina</th>
            <th scope="col">Serie</th>
            <th scope="col">Observación</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of serviciosEnCurso; let i=index">
            <td scope="row">{{item.callID}}</td>
            <td>{{item.F_SOLICITUD}}</td>
            <td>{{item.F_ASISTENCIA_TECNICA}}</td>
            <td>{{item.ItemName}}</td>
            <td>{{item.internalSN}}</td>
            <td><span class="btn-ver"  (click)="verObservaciones(item.subject);">VER</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<div *ngIf="this.paginaActual=='HistoricoSolicitudes'">
  <div class="container contenedor-tabla" data-aos="fade-up" data-aos-delay="300">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color7">HISTORICO SOLICITUDES</h1>
    </div>
    <div class="container scroll-eje-x">
      <table class="table table-striped ancho-mobile2">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Nº LL/S</th>
            <th scope="col">Fecha de solicitud</th>
            <th scope="col">Fecha de asistencia</th>
            <th scope="col">Máquina</th>
            <th scope="col">Serie</th>
            <th scope="col">Estado</th>
            <th scope="col">Observación</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of historicoSolcitudes; let i=index">
            <td scope="row">{{item.callID}}</td>
            <td>{{item.F_SOLICITUD}}</td>
            <td>{{item.F_ASISTENCIA_TECNICA}}</td>
            <td>{{item.ItemName}}</td>
            <td>{{item.internalSN}}</td>
            <td>
              <div>
                <label class="switch font-20">
                    <input type="checkbox" [(ngModel)]="item.estado" disabled>
                    <span class="slider"></span>
                </label>
              </div>
            </td>
            <td><span class="btn-ver"  (click)="verObservaciones(item.subject);">VER</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>




<app-btn-circle></app-btn-circle>
