<div class="header-coti" data-aos="fade-in" data-aos-delay="100">
    <div class="container">
        <div class="content-block-header" data-aos="fade-in" data-aos-delay="200">
            <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
            <div class="wrapper-header-coti">
                <a class="remover-line" href="/menu">
                    <div class="burger"><span class="class-home icon-home-01"></span></div>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Modal body text goes here.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary">Save changes</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

<div class="content-maquinas-general">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
        <h1 class="title-start-color1">MIS MÁQUINAS</h1>
    </div>


    <!-- "internalSN"
      "manufSN"
      "createDate"
      "U_INDS_IT_ANIO"
      "U_INDS_IT_PDF_URL"
      "U_URL_Imagen_SKU"
      "Marca"
      "U_Modelo"
      "U_URL_Imagen_Marca"
      "itemCode"
      "ItemName" -->

    <div class="wrapper--maquinas" data-aos="fade-up" data-aos-delay="200">
        <div class="content--maquinas" *ngFor="let item of maquinas; let i=index">
            <div class="box-maquinas">
                <img class="img-ajustar" src="{{item.U_URL_Imagen_SKU}}" alt="">
            </div>
            <div class="box-titles-maquinas">
                <ul class="lista-maquinas">
                    <li class="titulo-maquinas"> {{item.ItemName}} </li>
                    <li class="color-maquinas">{{item.U_Modelo}}</li>
                    <li>Serie: {{item.internalSN}}</li>
                    <li>Fecha de Instalación: {{item.U_INDS_IT_ANIO}}</li>
                </ul>
                <div class="botones-maquinas">
                    <a (click)="verPdf(item)">
                        <button id="ver-pdf" class="btn-ficha">FICHA TÉCNICA</button>
                    </a>



                    <!-- <button class="btn-ficha" (click)="btnBuscarMaquina();">
                        FICHA TÉCNICA
                    </button> -->



                    <a>
                        <button class="btn-historicos2" (click)="cargarDatos(item)">HISTÓRICO</button>
                    </a>
                </div>
            </div>
        </div>


        <!-- <div class="content--maquinas">
              <div class="box-maquinas">
                  <img class="img-ajustar" src="/assets/img/embutidora.jpeg" alt="">
              </div>
              <div class="box-titles-maquinas">
                  <ul class="lista-maquinas">
                      <li class="titulo-maquinas">INYECTORA PI 21 M</li>
                      <li class="color-maquinas">GUNTHER</li>
                      <li>Serie: 36890</li>
                      <li>Año Fab. 2015</li>
                  </ul>
                  <div class="botones-maquinas">
                      <a href="/verPdf">
                          <button id="ver-pdf" class="btn-ficha">FICHA TÉCNICA</button>
                      </a>
                      <a href="/historico">
                          <button class="btn-historicos2">HISTÓRICO</button>
                      </a>
                  </div>
              </div>
          </div>
          <div class="content--maquinas">
              <div class="box-maquinas">
                  <img class="img-ajustar" src="/assets/img/termoselladora.jpeg" alt="">
              </div>
              <div class="box-titles-maquinas">
                  <ul class="lista-maquinas">
                      <li class="titulo-maquinas">INYECTORA PI 21 M</li>
                      <li class="color-maquinas">GUNTHER</li>
                      <li>Serie: 36890</li>
                      <li>Año Fab. 2015</li>
                  </ul>
                  <div class="botones-maquinas">
                      <a href="/verPdf">
                          <button id="ver-pdf" class="btn-ficha">FICHA TÉCNICA</button>
                      </a>
                      <a href="/historico">
                          <button class="btn-historicos2">HISTÓRICO</button>
                      </a>
                  </div>
              </div>
          </div>
          <div class="content--maquinas">
              <div class="box-maquinas">
                  <img class="img-ajustar" src="/assets/img/tunel.jpeg" alt="">
              </div>
              <div class="box-titles-maquinas">
                  <ul class="lista-maquinas">
                      <li class="titulo-maquinas">INYECTORA PI 21 M</li>
                      <li class="color-maquinas">GUNTHER</li>
                      <li>Serie: 36890</li>
                      <li>Año Fab. 2015</li>
                  </ul>
                  <div class="botones-maquinas">
                      <a href="/verPdf">
                          <button id="ver-pdf" class="btn-ficha">FICHA TÉCNICA</button>
                      </a>
                      <a href="/historico">
                          <button class="btn-historicos2">HISTÓRICO</button>
                      </a>
                  </div>
              </div>
          </div>
          <div class="content--maquinas">
              <div class="box-maquinas">
                  <img class="img-ajustar" src="/assets/img/tfs80.jpeg" alt="">
              </div>
              <div class="box-titles-maquinas">
                  <ul class="lista-maquinas">
                      <li class="titulo-maquinas">INYECTORA PI 21 M</li>
                      <li class="color-maquinas">GUNTHER</li>
                      <li>Serie: 36890</li>
                      <li>Año Fab. 2015</li>
                  </ul>
                  <div class="botones-maquinas">
                      <a href="/verPdf">
                          <button id="ver-pdf" class="btn-ficha">FICHA TÉCNICA</button>
                      </a>
                      <a href="/historico">
                          <button class="btn-historicos2">HISTÓRICO</button>
                      </a>
                  </div>
              </div>
          </div>
          <div class="content--maquinas">
              <div class="box-maquinas">
                  <img class="img-ajustar" src="/assets/img/corte.jpeg" alt="">
              </div>
              <div class="box-titles-maquinas">
                  <ul class="lista-maquinas">
                      <li class="titulo-maquinas">INYECTORA PI 21 M</li>
                      <li class="color-maquinas">GUNTHER</li>
                      <li>Serie: 36890</li>
                      <li>Año Fab. 2015</li>
                  </ul>
                  <div class="botones-maquinas">
                      <a href="/verPdf">
                          <button id="ver-pdf" class="btn-ficha">FICHA TÉCNICA</button>
                      </a>
                      <a href="/historico">
                          <button class="btn-historicos2">HISTÓRICO</button>
                      </a>
                      </div>
                  </div>
              </div> -->
    </div>
</div>


<app-btn-circle></app-btn-circle>
