<div class="header-coti" data-aos="fade-in" data-aos-delay="100">
    <div class="container">
      <div class="content-block-header" data-aos="fade-in" data-aos-delay="200">
        <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
        <div class="wrapper-header-coti">
          <a class="remover-line" href="/menu">
            <div class="burger"><span class="class-home icon-home-01"></span></div>
          </a>
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="wrapper-accordion">
      <ul class="ul-lista-personal">
      <hr data-aos="fade-up" data-aos-delay="50">
          <div class="wrapper-personal" data-aos="fade-up" data-aos-delay="100">
              <img class="img-personal" src="/assets/img/user-vector.svg" alt="">
              <div class="content-personal">
                  <h1 class="title-personal">Bienvenido</h1>
                  <h2 class="subtitle-personal">{{usuario.NOMBRE_COMPLETO}}</h2>
              </div>
          </div>
          <hr data-aos="fade-up" data-aos-delay="150">
          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="200">
              <a class="title-accordion"><span class="title--bold">Nombre:</span> 
                <span class="txt--date">{{usuario.NOMBRE_COMPLETO}}</span></a>
          </li>
          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="200">
              <a class="title-accordion"><span class="title--bold">Oficina:</span> 
                <span class="txt--date">{{usuario.OFICINA}}</span></a>
          </li>
          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="300">
              <a class="title-accordion"><span class="title--bold">Área:</span> 
                <span class="txt--date">{{usuario.AREA}}</span></a>
          </li>
          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="400">
              <a class="title-accordion"><span class="title--bold">Correo:</span> 
                <span class="txt--date">{{usuario.CORREO}}</span></a>
          </li>
          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="500">
              <a class="title-accordion"><span class="title--bold">Teléfono Movil:</span> 
                <span class="txt--date">{{usuario.CELULAR}}</span></a>
          </li>

          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="600"  *ngFor="let item of facturacion; let i=index">
              <a class="title-accordion"><span class="title--bold">Facturacion:</span> 
                <span class="txt--date">{{item.Street}}, {{item.County}}, {{item.City}}</span></a>
          </li>
          <li class="li-accordion-personal" data-aos="fade-up" data-aos-delay="700"  *ngFor="let item of despacho; let i=index">
            <a class="title-accordion"><span class="title--bold">Despacho:</span> 
              <span class="txt--date">{{item.Street}}, {{item.County}}, {{item.City}}</span></a>
        </li>
      </ul>
  </div>
  
  <app-btn-circle></app-btn-circle>