import { NgForOf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiCallService } from '../api-call.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import Swal from 'sweetalert2'
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    private _api: ApiCallService,
    private _formBuilder: FormBuilder,
    private _dbService: NgxIndexedDBService,
    private _cookieService: CookieService,
    private _route: Router,
  ) {

  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });


    //var datos = {"id": 1, "token": "asdfas"};
    //this._dbService.update('Parametros', datos);





    this._dbService.getByKey('Parametros', 1).subscribe((par) => {
      if (par) {
        var parametros: any = par;
        var token = parametros.token;
        this._cookieService.set("token", token);
        this._api.ValidToken().subscribe(res => {

          //console.log(res);
          if (res.success) {
            //guarda token e iniciamos sesion automatica

            this.loginExitoso();
          }
          else {
            //mansamos mensaje
            Swal.fire(
              'Login',
              res.message,
              'error'
            )

            //this._dbService.deleteByKey("Parametros", 1);
          }


        });
      }
    });

  }


  loginExitoso() {
    this._route.navigate(['/menu']);
  }


  clientes: any = [];


  login(primerPaso: boolean, CardCode: string) {
    if (primerPaso) {
      var email = this.loginForm.value.email;
      var password = this.loginForm.value.password;
      this._api.login(email, password).subscribe(res => {
        if (res) {
          //console.log('res login', res);
          if (res.success) {
            this._cookieService.set("token", res.token);
            this.clientes = res.clientes;
            //console.log('clientes', this.clientes)
            this._cookieService.set("perfil", res.perfil);
            document.getElementsByClassName("wrapper-mover-elementos").item(0).classList.toggle("mover-left");

          }
          else {

            Swal.fire(
              'Login',
              res.message,
              'error'
            )
          }
        }

      })
    }
    else {

      this._api.setCardCodeSession(CardCode).subscribe(res => {

        if (res) {
          //console.log('res carcode', res);
          if (res.success) {
            var token = this._cookieService.get("token");
            var datos = { "id": 1, "token": token };
            this._dbService.update('Parametros', datos);
            //this._cookieService.set("token", this.tokenGlobal);
            this.loginExitoso();

            //console.log(CardCode);

          }
          else {

            Swal.fire(
              'Login',
              res.message,
              'error'
            )
          }
        }
      })

    }
  }

  moverElementos() {
    document.getElementsByClassName("wrapper-mover-elementos").item(0).classList.toggle("mover-left");
  }

  
  volverLogin(){
    document.getElementsByClassName("wrapper-mover-elementos").item(0).classList.toggle("back-login");
  }




  BuscarEmpresa() {
    var email = this.loginForm.value.email;
    var password = this.loginForm.value.password;
    let textvalue = (<HTMLInputElement>document.getElementById("buscar")).value;
    textvalue = textvalue.toUpperCase()
    //console.log(textvalue)
    if (textvalue != "") {
      this.clientes = [];
      this._api.login(email, password).subscribe(res => {
        //console.log(res);
        var Nombre = "";
        res.clientes.forEach(element => {

          if (element.CardName) {
            Nombre = element.CardName.toUpperCase();
          }
          if (Nombre.includes(textvalue)) {
            this.clientes.push(element)
          }
        });
      })
    } else {
      this._api.login(email, password).subscribe(res => {
        //console.log(res);
        this.clientes = res.clientes;
      })

    }

  }



}
