import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuComponent } from './menu/menu.component'
import { CuentaPersonalComponent } from './cuenta-personal/cuenta-personal.component'
import { MisMaquinasComponent } from './mis-maquinas/mis-maquinas.component';
import { QrComponent } from './qr/qr.component';
import { SolicitudDeServicioComponent } from './solicitud-de-servicio/solicitud-de-servicio.component';
import { VerPdfComponent } from './ver-pdf/ver-pdf.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { OcComponent } from './oc/oc.component';
import { LoginComponent } from './login/login.component';
import { CuentasClarasNGComponent } from './cuentas-claras-ng/cuentas-claras-ng.component';
import { HistoricoComponent } from './historico/historico.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'cotizaciones', component: CotizacionesComponent },
  { path: 'cotizaciones/:parametro', component: CotizacionesComponent },
  { path: 'cuentaPersonal', component: CuentaPersonalComponent },
  { path: 'login', component: LoginComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'misMaquinas', component: MisMaquinasComponent },
  { path: 'historico', component: HistoricoComponent },
  { path: 'historico/:ItemCode/:InternalSN', component: HistoricoComponent },
  { path: 'oc', component: OcComponent },
  { path: 'qr', component: QrComponent },
  { path: 'solicitudDeServicio', component: SolicitudDeServicioComponent },
  { path: 'solicitudDeServicio/:parametro', component: SolicitudDeServicioComponent },
  { path: 'verPdf/:urlpdf', component: VerPdfComponent },
  { path: 'cuentasClaras', component: CuentasClarasNGComponent },
  { path: 'cuentasClaras/:parametro', component: CuentasClarasNGComponent },
  //{ path: 'cuentasClaras', component: CuentasClarasComponent },
  //{ path: 'facturasPorVencer', component: FacturasPorVencerComponent },
  //{ path: 'facturasVencidas', component: FacturasVencidasComponent },
  //{ path: 'grafico', component: GraficoComponent },
  //{ path: 'graficoHistorico', component: GraficoHistoricoComponent },
  //{ path: 'notasDeCredito', component: NotasDeCreditoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
