import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global.service"
import * as moment from 'moment';

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {
  public cotizaciones: any = [];
  public cotizacionesHistoricas: any = [];
  public nv: any = [];
  public isChecked: any;
  paginaActual: string;
  //select
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  MaquinaUnica: any[] = [];
  maquinaDato: any[] = [];

  //foltro fecha
  public inicio: any;
  public termino: any;
  //filtro
  bandera: any;
  public estado: any;

  constructor(
    private _api: ApiCallService,
    private _router: Router,
    private _routerURL: ActivatedRoute,
  ) { }

  ngOnInit(): void {


    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };


    //console.log(this._routerURL.snapshot.paramMap.get("parametro"));
    if(this._routerURL.snapshot.paramMap.get("parametro"))
    {
      this.paginaActual = this._routerURL.snapshot.paramMap.get("parametro");
    }
    else
    {
      this.paginaActual = "cotizacion";

    }


    //this.paginaActual = "nv";
    this._api.MisPedidosSolicitados().subscribe(res => {
      var arregleglo: any = [];
      this.nv = [];
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      var unMesMenos = moment(Date.now()).subtract(1, "month").toDate();
      arregleglo = res;
      arregleglo.forEach(element => {

        var DocDate = element.DocDate.substring(0, 10);
        var arreglo = DocDate.split('-');
        DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
        element.DocDate = DocDate;


        var DocDueDate = element.DocDate.substring(0, 10);
        //arreglo = DocDueDate.split('-');
        //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
        element.DocDueDate = DocDueDate;

        if (element.U_FE_FECHADB2) {
          var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          element.U_FE_FECHADB2 = U_FE_FECHADB2;
        }


        element.vigente = "Si";

        // element.U_INDS_PS_COTI_ACEPTADA=true;
        if (element.ESTADO_PAGO == "VENCIDA") {
          element.vigente = "No";

          // element.U_INDS_PS_COTI_ACEPTADA = false;
        }
        if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
          element.U_INDS_PS_COTI_ACEPTADA = false;
          element.disabled = null;
        } else {
          element.U_INDS_PS_COTI_ACEPTADA = true;
          element.disabled = "Y";
        }


        if (!element.Maquina)
          element.Maquina = "";
        if (!element.Maquina_descripcion)
          element.Maquina_descripcion = "";
        if (!element.Maquina_descripcion)
          element.Maquina_descripcion = "";



        element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

        if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
          this.cotizaciones.push(element);
        }
        if (element.ORIGEN == "COTIZACION") {
          this.cotizacionesHistoricas.push(element);
        }
        else if (element.ORIGEN == "NOTA DE VENTA") {
          this.nv.push(element);
        }


        console.log(this.cotizaciones);
      });

      const key = 'Maquina';

      this.MaquinaUnica = [...new Map(res.map(item =>
        [item[key], item]
      )).values()];

      //console.log(this.MaquinaUnica);
      this.MaquinaUnica.forEach(aa => {
        if (aa.Maquina == "" || aa.Maquina == null) {
          this.maquinaDato.push({ item_id: aa.Maquina, item_text: "Sin Maquina Asociada" })
        } else {
          this.maquinaDato.push({ item_id: aa.Maquina, item_text: aa.Maquina_descripcion })
        }

      })
      this.dropdownList = this.maquinaDato

      //console.log(this.cotizaciones);
      //console.log(this.nv)
    })

  }


  AbrirPDFMaquina(item) {
    if (item.U_INDS_IT_PDF_URL) {
      this._router.navigate(["/verPdf", item.U_INDS_IT_PDF_URL]);
    }
    else {
      Swal.fire("Maquina no cuenta con ficha tecnica");
    }
  }

  AbrirHistorico(item) {
    if (item.Serie) {
      this._router.navigate(["/historico", item.Maquina, item.Serie]);
    }
    else {
      Swal.fire("Documento no cuenta con maquina relacionada");
    }
  }


  AbrirAdjunto(item) {
    var ruta = item.RUTA_ADJUNTO;
    if (ruta) {
      var urlFinal = GlobalService.urlAPI + "Viewer/View?path=" + ruta;
      this._router.navigate(["/verPdf", urlFinal]);
    }
    else {
      Swal.fire("Documento no cuenta con adjunto");
    }
  }

  AbreMenu() {
    let menu = document.getElementById("menu");
    menu.classList.toggle("change-menu");
    menu.classList.toggle("change");

    document.getElementsByClassName("panel-right-emergent").item(0).classList.toggle("mostrar-panel-right");
    document.getElementById("ver-animation").classList.toggle("animate__animated animate__fadeInRight");
  }

  AbrirFiltro() {
    document.getElementsByClassName("panel-left-emergent").item(0).classList.toggle("mostrar-panel-left");
    document.getElementById("left-animation").classList.toggle("animate__animated animate__fadeInLeft");


  }

  verObservaciones(mensaje) {
    Swal.fire(mensaje);
  }

  cambioElemento(pagina: string) {
    console.log(pagina)
    this._router.navigate(["/cotizaciones/" + pagina]);
    this.paginaActual = pagina;
    this.AbreMenu();
  }


  pasaPesosChilenos(money) {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(money);
  }
  AceptarCotizacion(index,item) {

    console.log(index);
    console.log(this.cotizaciones[index].DocEntry);
    console.log(this.cotizaciones[index].vigente);
    console.log(this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA);

    //console.log("item", item)

    if (item.vigente == "Si") {
      //UPdate
      Swal.fire({
        title: 'Aceptar documento',
        text: "---",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Aprobar`,
        icon: "success",
        denyButtonText: "No aprobar",
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("Flujo de aprobacion");
          this._api.AceptarDocumento(this.cotizaciones[index].DocEntry, this.cotizaciones[index].DocNum, this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA).subscribe(res => {
            console.log(res);
          })
        } else {
          console.log("Denied");
          if (this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA) {
            this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA = false;
          }
          else {
            this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA = true;
          }

        }
      })
      // this._api.AceptarDocumento(this.cotizaciones[index].DocEntry,this.cotizaciones[index].DocNum, this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA);
    } else {
      //Enviar correo
      Swal.fire({
        title: 'Aceptar documento',
        text: "Documento no es vigente, por lo que se enviara a aprobacion por TAGLER.",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Enviar`,
        icon: "warning",
        denyButtonText: "No enviar",
        allowEscapeKey: false,
        allowOutsideClick: false

      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("Flujo de aprobacion");
          this._api.AceptarDocumento(this.cotizaciones[index].DocEntry, this.cotizaciones[index].DocNum, this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA).subscribe(res => {
            console.log(res);
            Swal.fire({
              title: 'Aceptar documento',
              text: res.toString(),
              showDenyButton: false,
              showCancelButton: false,
              icon: "success",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 3000

            })
          })
        } else {
          console.log("Denied");
          if (this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA) {
            this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA = false;
          }
          else {
            this.cotizaciones[index].U_INDS_PS_COTI_ACEPTADA = true;
          }
        }
      })

    }




  }


  filtro() {
    var unMesMenos = moment(Date.now()).subtract(1, "month").toDate();
    this.bandera = false;

    if (this.inicio && !this.termino) {
      //console.log("ingrese termino")
      var fecha = new Date().toString();
      this.termino=fecha.substring(0,10);
      console.log( this.termino)
    }

    if (!this.inicio && this.termino) {
      //console.log("ingrese inicio")
      var today = new Date();
      this.inicio = new Date(today.setMonth(today.getMonth() - 6).toString().substring(0,10));
    }

    if (this.inicio && this.termino) {
      //console.log('inicio', this.inicio, '  termino', this.termino)
    }

    if (this.selectedItems) {
      //console.log("estas son las maquinas", this.selectedItems);
    }
    console.log('estado', this.estado)
    if (this.estado) {
      console.log('estado', this.estado)
      if (this.estado == 1) {
        this.estado = "Si"
      }
      if (this.estado == 2) {
        this.estado = "No"
      }
      if (this.estado == 3) {
        this.estado = "TODO"
      }
    }
    //console.log('inicio', this.inicio, '  termino', this.termino)

    //solo fechas
    if (this.inicio != null && this.termino != null && this.selectedItems.length == 0 ) {
      console.log('solo fechas'  )
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        console.log(res)
        arregleglo.forEach(element => {
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fechater = new Date(this.termino + " 00:00:00")
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          //var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          //element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          var fechaDocumento = new Date(element.TaxDate.substring(0,10) + " 00:00:00")
          console.log("fecha dato", fechaDocumento, " fecha inicial", fechaini, '  fecha termino', fechater)
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fechater) {


              if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                this.cotizaciones.push(element);
              }
              if (element.ORIGEN == "COTIZACION") {
                this.cotizacionesHistoricas.push(element);
              }
              else if (element.ORIGEN == "NOTA DE VENTA") {
                this.nv.push(element);
              }
            }
          }

          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.cotizacionesHistoricas);
        console.log(this.nv)
      })

    }
    //fecha y estado
    if (this.inicio != null && this.termino != null && this.selectedItems.length == 0) {
      console.log('fechas y estado')
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        arregleglo.forEach(element => {
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fechater = new Date(this.termino + " 00:00:00")
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          //var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          //element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          var fechaDocumento = new Date(element.TaxDate)
          console.log("fecha dato", fechaDocumento, " fecha inicial", fechaini, '  fecha termino', fechater)
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fechater) {
              if (this.estado == "Si" || this.estado == "No") {
                if (element.vigente == this.estado) {

                  if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                    this.cotizaciones.push(element);
                  }
                  if (element.ORIGEN == "COTIZACION") {
                    this.cotizacionesHistoricas.push(element);
                  }
                  else if (element.ORIGEN == "NOTA DE VENTA") {
                    this.nv.push(element);
                  }
                }
              }/* else{
                if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                  this.cotizaciones.push(element);
                }
                if (element.ORIGEN == "COTIZACION") {
                  this.cotizacionesHistoricas.push(element);
                }
                else if (element.ORIGEN == "NOTA DE VENTA") {
                  this.nv.push(element);
                }
              } */
            }
          }
          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.cotizacionesHistoricas);
        console.log(this.nv)
      })

    }
    //fhecha y maquina
    if (this.inicio != null && this.termino != null && this.selectedItems.length >= 1 ) {
      console.log(' fechas y maquina')
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        arregleglo.forEach(element => {
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fetater = new Date(this.termino + " 00:00:00")
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          //var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          //element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          var fechaDocumento = new Date(element.TaxDate)
          this.selectedItems.forEach(itemMaquina => {
            if (fechaDocumento >= fechaini) {
              if (fechaDocumento <= fetater) {
                if (element.Maquina == itemMaquina.item_id) {

                  if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                    this.cotizaciones.push(element);
                  }
                  if (element.ORIGEN == "COTIZACION") {
                    this.cotizacionesHistoricas.push(element);
                  }
                  else if (element.ORIGEN == "NOTA DE VENTA") {
                    this.nv.push(element);
                  }
                }
              }
            }
          })
          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.nv)
      })

    }
    //fecha, maquina y estado
    if (this.inicio != null && this.termino != null && this.selectedItems.length >= 1 && this.estado != null) {
      console.log(' fechas, maquina y estado')
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        arregleglo.forEach(element => {
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fetater = new Date(this.termino + " 00:00:00")
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          var fechaDocumento = new Date(element.TaxDate)

          this.selectedItems.forEach(itemMaquina => {
            if (fechaDocumento >= fechaini) {
              if (fechaDocumento <= fetater) {
                if (element.Maquina == itemMaquina.item_id) {
                  if (this.estado == "Si" || this.estado == "No") {
                    if (element.vigente == this.estado) {

                      if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                        this.cotizaciones.push(element);
                      }
                      if (element.ORIGEN == "COTIZACION") {
                        this.cotizacionesHistoricas.push(element);
                      }
                      else if (element.ORIGEN == "NOTA DE VENTA") {
                        this.nv.push(element);
                      }
                    }
                  }else{
                    if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                      this.cotizaciones.push(element);
                    }
                    if (element.ORIGEN == "COTIZACION") {
                      this.cotizacionesHistoricas.push(element);
                    }
                    else if (element.ORIGEN == "NOTA DE VENTA") {
                      this.nv.push(element);
                    }
                  }
                }
              }
            }
          })

          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.nv)
      })

    }
    //solo maquina
    if (this.inicio == null && this.termino == null && this.selectedItems.length >= 1 ) {
      console.log(' solo maquina')
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        arregleglo.forEach(element => {
          //var fechaini = new Date(this.inicio + " 00:00:00")
          //var fetater = new Date(this.termino + " 00:00:00")
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          //var fechaDocumento = new Date(element.TaxDate)
          this.selectedItems.forEach(itemMaquina => {

            if (element.Maquina == itemMaquina.item_id) {

              if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                this.cotizaciones.push(element);
              }
              if (element.ORIGEN == "COTIZACION") {
                this.cotizacionesHistoricas.push(element);
              }
              else if (element.ORIGEN == "NOTA DE VENTA") {
                this.nv.push(element);
              }

            }
          })
          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.nv)
      })
    }
    //maquina y estado
    if (this.inicio == null && this.termino == null && this.selectedItems.length >= 1 && this.estado != null) {
      console.log('maquina y estado')
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        arregleglo.forEach(element => {
          var fechaini = new Date(this.inicio)
          var fetater = new Date(this.termino)
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          var fechaDocumento = new Date(element.TaxDate)

          this.selectedItems.forEach(itemMaquina => {

            if (element.Maquina == itemMaquina.item_id) {
              if (this.estado == "Si" || this.estado == "No") {
                if (element.vigente == this.estado) {

                  if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                    this.cotizaciones.push(element);
                  }
                  if (element.ORIGEN == "COTIZACION") {
                    this.cotizacionesHistoricas.push(element);
                  }
                  else if (element.ORIGEN == "NOTA DE VENTA") {
                    this.nv.push(element);
                  }
                }
              } else {
                if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                  this.cotizaciones.push(element);
                }
                if (element.ORIGEN == "COTIZACION") {
                  this.cotizacionesHistoricas.push(element);
                }
                else if (element.ORIGEN == "NOTA DE VENTA") {
                  this.nv.push(element);
                }
              }
            }
          })
          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.nv)
      })
    }
    //solo estado
    if (this.inicio == null && this.termino == null && this.selectedItems.length == 0 && this.estado != null) {
      console.log('solo estado')
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        arregleglo = res;
        arregleglo.forEach(element => {
          var fechaini = new Date(this.inicio)
          var fetater = new Date(this.termino)
          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;


          var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";

          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          var fechaDocumento = new Date(element.TaxDate)
          console.log(this.estado)
          if (this.estado == "Si" || this.estado == "No") {
            if (element.vigente == this.estado) {

              if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
                this.cotizaciones.push(element);
              }
              if (element.ORIGEN == "COTIZACION") {
                this.cotizacionesHistoricas.push(element);
              }
              else if (element.ORIGEN == "NOTA DE VENTA") {
                this.nv.push(element);
              }
            }
          } else {
            if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
              this.cotizaciones.push(element);
            }
            if (element.ORIGEN == "COTIZACION") {
              this.cotizacionesHistoricas.push(element);
            }
            else if (element.ORIGEN == "NOTA DE VENTA") {
              this.nv.push(element);
            }
          }
          //console.log(element);
        });

        console.log(this.cotizaciones);
        console.log(this.nv)
      })
    }
    this.AbrirFiltro();

  }
  quitarfiltro() {
    var unMesMenos = moment(Date.now()).subtract(1, "month").toDate();
    if (this.inicio == null && this.termino == null && this.selectedItems.length == 0 ) {
      console.log("sin filtro anterior");
    } else {
      console.log("limpia y recarga datos original");
      this.inicio = null;
      this.termino = null;
      this.selectedItems = [];
      this.cotizaciones = [];
      this.cotizacionesHistoricas = [];
      this.nv = [];

      var today = new Date();
      var fechaAnterior = new Date(today.setMonth(today.getMonth() - 6));
      //console.log("hola");
      this._api.MisPedidosSolicitados().subscribe(res => {
        var arregleglo: any = [];
        this.nv = [];
        this.cotizaciones = [];
        this.cotizacionesHistoricas = [];
        arregleglo = res;
        arregleglo.forEach(element => {

          var DocDate = element.DocDate.substring(0, 10);
          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDate = DocDate;


          var DocDueDate = element.DocDate.substring(0, 10);
          //arreglo = DocDueDate.split('-');
          //DocDueDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];
          element.DocDueDate = DocDueDate;

          //var U_FE_FECHADB2 = element.U_FE_FECHADB2.substring(0, 10);
          //element.U_FE_FECHADB2 = U_FE_FECHADB2;

          element.vigente = "Si";

          // element.U_INDS_PS_COTI_ACEPTADA=true;
          if (element.ESTADO_PAGO == "VENCIDA") {
            element.vigente = "No";

            // element.U_INDS_PS_COTI_ACEPTADA = false;
          }
          if (element.U_INDS_PS_COTI_ACEPTADA == "N") {
            element.U_INDS_PS_COTI_ACEPTADA = false;
            element.disabled = null;
          } else {
            element.U_INDS_PS_COTI_ACEPTADA = true;
            element.disabled = "Y";
          }


          if (!element.Maquina)
            element.Maquina = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";
          if (!element.Maquina_descripcion)
            element.Maquina_descripcion = "";



          element.totalCLP = this.pasaPesosChilenos(element.DocTotal);

          if (element.ORIGEN == "COTIZACION" && moment(element.TaxDate).toDate() > unMesMenos) {
            this.cotizaciones.push(element);
          }
          if (element.ORIGEN == "COTIZACION") {
            this.cotizacionesHistoricas.push(element);
          }
          else if (element.ORIGEN == "NOTA DE VENTA") {
            this.nv.push(element);
          }


          //console.log(element);
        });
        console.log(this.cotizaciones);
        console.log(this.nv)
      })
    }
    this.AbrirFiltro();

  }



}
