import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  public url: string;
  constructor(
    private _http: HttpClient,
    private _cookieService: CookieService,
  ) {
    this.url =  GlobalService.urlAPI + "api"
  }

  login(email: string, password: string): Observable<any> {

    const body =
    {
      "email": email,
      "password": password
    }
    return this._http.post(this.url + '/Session/Login', body);
  }
  ValidToken(): Observable<any> {

    var token = this._cookieService.get('token');
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'token': token
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.post(this.url + '/Session/ValidToken', null, requestOptions);

  }
  SignOff(): Observable<any> {

    var token = this._cookieService.get('token');
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'token': token
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.post(this.url + '/Session/SignOff', null, requestOptions);

  }

  getLogin(): Observable<any> {

    var token = this._cookieService.get('token');
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'token': token
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.get(this.url + '/Session/getLogin', requestOptions);

  }

  setCardCodeSession(CardCode:String): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Session/setCardCodeSession?token=' + token + "&CardCode=" + CardCode);

  }

  getDireccion(): Observable<any> {

    var token = this._cookieService.get('token');
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'token': token
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.get(this.url + '/Session/getDireccion', requestOptions);

  }

  facturasEmitidas(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Factura/facturasEmitidas?token=' + token);

  }

  facturasPorVencer(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Factura/facturasPorVencer?token=' + token);

  }

  facturasVencidas(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Factura/facturasVencidas?token=' + token);

  }
  NotasCredito(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Factura/NotasCredito?token=' + token);
  }
  CuentasClaras(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Factura/CuentasClaras?token=' + token);
  }

  TarjetasDeEquipo(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Service/TarjetasDeEquipo?token=' + token);
  }

  HistoricoMaquina(ItemCode: string, MnfSerial: string): Observable<any> {
    var token = this._cookieService.get('token');
    const headers = { 'token': token };
    const body = {
      ItemCode: ItemCode,
      MnfSerial: MnfSerial
    };
    return this._http.post(this.url + '/Service/HistoricoMaquina', body, { headers });
  }

  Cotizaciones() {
    return this._http.get(this.url + '/Factura/ObtenerCot?CardCode=' + this._cookieService.get('token'));
  }

  Pedidos(CardCode: string) {
    return this._http.get(this.url + '/Factura/ObtenerPed?CardCode=' + this._cookieService.get('token'));
  }
  MisPedidosSolicitados(): Observable<any>  {
    return this._http.get(this.url + '/Pedidos/MisPedidosSolicitados?token=' + this._cookieService.get('token'));
  }


  SOLICITUD_SERVICIO(PERIODO_SERVICIO: string, SOLICITUD: string, CardCode: string, ItemCode: string, ItemName: string, MnfSerial: string): Observable<any> {

    var token = this._cookieService.get('token');
    const headers = { 'token': token };
    const body = {
      ID: 0,
      CORREO: "",
      PERIODO_SERVICIO: PERIODO_SERVICIO,
      SOLICITUD: SOLICITUD,
      F_SOLICITUD: "",
      F_SISTEMA: "",
      CardCode: CardCode,
      ItemCode: ItemCode,
      MnfSerial: MnfSerial,
      F_ACTUALIZACION: "",
      ItemName: ItemName
    };

    console.log(body);
    //return null;

    return this._http.post(this.url + '/Service/SOLICITUD_SERVICIO', body, { headers });
  }


  ObtieneServiciosTodos(): Observable<any> {

    var token = this._cookieService.get('token');
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'token': token
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._http.post(this.url + '/Service/ObtieneServiciosTodos', null, requestOptions);

  }
  InversionAgrupado(): Observable<any> {
    var token = this._cookieService.get('token');
    return this._http.get(this.url + '/Factura/InversionAgrupado?token=' + token + "&condicion=");

  }
  InversionActual(condicion): Observable<any> {
    var token = this._cookieService.get('token');
    var param = "InversionActual?token=" + token + "&condicion=" + condicion;
    return this._http.get(this.url + '/Factura/' + param);

  }

  SendMail(DocEntry) {
    var token = this._cookieService.get('token');
    return this._http.post(this.url + '/Service/SendMail?token=' + token + "&DocEntry=" + DocEntry, "");

  }

  AceptarDocumento(DocEntry, DocNum, Estado) {
    var token = this._cookieService.get('token');
    return this._http.post(this.url + '/Service/UpdateDocument?token=' + token + "&DocEntry=" + DocEntry + "&DocNum=" + DocNum + "&aprobacion=" + Estado, "");
  }
}
