



  <div class="container contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
        <h1 class="title-start-color2">OC PENDIENTES O EN CURSO</h1>
    </div>
    <div class="container scroll-eje-x">
        <table class="table ancho-mobile">
            <thead>
              <tr>
                <th scope="col">Orden de compra</th>
                <th scope="col">Máquina</th>
                <th scope="col">Fecha de pedido</th>
                <th scope="col">Tipo de solicitud</th>
                <th scope="col">Comentario</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pedidos; let i=index">
                <th scope="row">{{item.DocNum}}</th>
                <td>{{item.Dscription}}</td>
                <td>{{item.DocDate}}</td>
                <td>{{Item.DocType}}</td>
                <td>{{item.Comments}}</td>
                <td>{{item.DocTotal}}</td>
              </tr>

            </tbody>
        </table>
    </div>
  </div>
