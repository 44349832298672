import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-btn-circle',
  templateUrl: './btn-circle.component.html',
  styleUrls: ['./btn-circle.component.scss']
})
export class BtnCircleComponent implements OnInit, OnDestroy {
  interval: any;
  constructor(
    private _api: ApiCallService,
    private _dbService: NgxIndexedDBService,
    private _cookieService: CookieService,
    private _route: Router,


  ) { }

  ngOnInit(): void {
    this.interval = setInterval(() => { this.validarSesion() }, 3000);
  }

  validarSesion() {
    this._api.ValidToken().subscribe(respuestaA => {
      if (respuestaA.success) {
        //console.log('user', respuestaA)
      } else {
        this.cerrarSession()
      }
    },
      (Error) => {
        this.cerrarSession()
      })
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)

  }

  verFooter() {
    document.getElementsByClassName("block-footer").item(0).classList.toggle("ver-menu");
    document.getElementsByClassName("mostrar-footer").item(0).classList.toggle("girar");
    document.getElementsByClassName("mostrar-footer").item(0).classList.toggle("subir");
    //document.getElementById("ver-footer").classList.toggle("girar");
    //document.getElementById("ver-footer").classList.toggle("subir");
  }


  cerrarSession() {
    this._api.SignOff();
    this._dbService.deleteByKey("Parametros", 1);
    this._cookieService.deleteAll();
    this._route.navigate(['/']);
  }

  navegar(url: string) {

    this._route.navigate(['/' + url]);
  }


}


