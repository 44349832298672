
<div class="block-gris" data-aos="fade-in">
    <img class="log-cuenta2" src="/assets/img/extendido.svg" alt="" data-aos="fade-in" data-aos-delay="200">
</div>
<div class="wrapper-login animate__animated animate__fadeIn">
    <div class="content-login">
        <div class="box-login">

            <div class="wrapper-maq-titles" data-aos="fade-up">
                <img class="maquito" src="/assets/img/maquito.png" alt="">
                <div class="content-titles-principal">
                    <h1 class="title-bienvenido">Bienvenido</h1>
                    <h2 class="sub-title">Ingrese su email y contraseña</h2>
                </div>
            </div>

            <hr data-aos="fade-up" data-aos-delay="100">

            <div class="wrapper-mover-elementos">
                <div class="mover-elementos">
                    <form class="form-login" name="loginForm" [formGroup]="loginForm" novalidate>
                        <input class="input-login" type="text" id="" name="" placeholder="Email" data-aos="fade-up" data-aos-delay="150"
                        formControlName="email" (keyup.enter)="login(true, '');">
                        <input class="input-login" type="password" id="" name="" placeholder="Contraseña" formControlName="password"
                        data-aos="fade-up" data-aos-delay="250" (keyup.enter)="login(true, '');">
                        <!--<a class="rename-pass" href="#" data-aos="fade-up" data-aos-delay="250">Olvidaste tu contraseña</a>-->
                    </form>
                    <button class="btn-sesion" data-aos="fade-up" data-aos-delay="350" (click)="login(true, '');">Iniciar Sesión</button>
                </div>

                <div class="seleccionar">
                    <div class="selection-and-search">
                        <!-- <h1 class="title-seleccion">SELECCIONA TU EMPRESA:</h1> -->
                        <input class="buscar_empresa" type="search" id="buscar" name="buscar" placeholder="Buscar Empresa..."  (keypress)="BuscarEmpresa()">
                    </div>

                    <ul class="ul-seleccionar">
                        <li  *ngFor="let item of clientes; let i=index"  class="li-seleccionar" (click)="login(false, item.CardCode);">{{item.CardName}}</li>
                    </ul>
                    <button class="btn-back" (click)="volverLogin();">Volver Sesión</button>
                </div>
            </div>
            <!--<a class="rename-cuenta" href="#" data-aos="fade-up" data-aos-delay="450">¿No tienes cuenta? Registrate.</a>-->
        </div>
    </div>
</div>



