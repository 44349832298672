import { Component, OnInit } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-ver-pdf',
  templateUrl: './ver-pdf.component.html',
  styleUrls: ['./ver-pdf.component.scss']
})
export class VerPdfComponent implements OnInit {

  constructor(
    private _api: ApiCallService, 
    private route: ActivatedRoute,

  ) { }

  urlpdf:string = "";
  ngOnInit(): void {

    this.urlpdf = this.route.snapshot.paramMap.get("urlpdf");
    (document.getElementById('iframe') as HTMLIFrameElement).src = this.urlpdf;
    console.log(this.urlpdf);
  }

  volverHistorico()
  {
    window.history.back();
  }

}
