import { Component, OnInit, ViewChild  } from '@angular/core';
import { BarcodeFormat } from 'angular-weblineindia-qrcode-scanner/library';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'



@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnInit {
 
  allowedFormats = [ BarcodeFormat.QR_CODE];


  dispositivo:string;

  
  constructor(
    private _router: Router
    ) {  }

  ngOnInit(): void {
  }

  scanSuccessHandler(textoQR:any)
  {
    var arreglo = textoQR.split('|');
    if (arreglo.length > 1) {
      var item = arreglo[0];
      var serie = arreglo[1];
      this._router.navigate(["/historico",item,serie])
    }
    else
    {
      Swal.fire(
        'QR',
        "Contenido escaneado: " + textoQR,
        'error'
      )
    }
    
    //console.log(alogo);
  }

  camerasFoundHandler(dispositivos:any)
  {
    console.log("olas", dispositivos);

    if (dispositivos.length > 0) {
      this.dispositivo = dispositivos[0].deviceId;
    }
    
  }

  camerasNotFoundHandler(event:any)
  {

  }
  onTorchCompatible(event:any)
  {
    console.log("torch compatible", event);
  }

}

