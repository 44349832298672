<div class="block-gris" data-aos="fade-in">
    <img class="log-cuenta2" src="/assets/img/extendido.svg" alt="" data-aos="fade-in" data-aos-delay="300">
</div>

<div class="wrapper-accordion">
    <ul class="ul-lista-accordion" data-aos="fade-up" data-aos-delay="100">

        <li class="li-accordion clip1" data-aos="fade-up" data-aos-delay="200">
            <a class="link--cuenta" href="/cuentaPersonal" class="title-accordion">
            <span class="iconos1 icon-usuario"></span>
            <span class="icon-position icon-usuario"></span> Mi Cuenta</a>

        </li>

        <li class="li-accordion clip2" data-aos="fade-up" data-aos-delay="200" *ngIf="Perfil=='Gold'">
            <a class="link--cuenta" href="/cuentasClaras" class="title-accordion">
            <span class="iconos2 icon-cuentas"></span>
            <span class="icon-position icon-cuentas"></span> Cuentas Claras</a>
        </li>
        <li class="li-accordion clip3" data-aos="fade-up" data-aos-delay="300" *ngIf="Perfil!='Silver'">
            <a class="link--cuenta" href="/cotizaciones" class="title-accordion">
            <span class="iconos3 icon-pedidos"></span>
            <span class="icon-position icon-pedidos"></span>Mis pedidos solicitados</a>
        </li>
        <li class="li-accordion clip4" data-aos="fade-up" data-aos-delay="400" ><!-- *ngIf="Perfil!='Global'" -->
            <a class="link--cuenta" href="/misMaquinas" class="title-accordion"><span class="iconos4 icon-maquinas"></span>
            <span class="icon-position icon-maquinas"></span> Mis máquinas</a>
        </li>
        <li class="li-accordion clip5" data-aos="fade-up" data-aos-delay="500" *ngIf="Perfil!='Silver'">
            <a class="link--cuenta" href="/solicitudDeServicio" class="title-accordion"><span class="iconos5 icon-servicios"></span>
            <span class="icon-position icon-servicios"></span> Solicitud de servicio</a>
        </li>
        <li class="li-accordion clip6" data-aos="fade-up" data-aos-delay="600">
            <a class="link--cuenta" href="#" class="title-accordion"><span class="iconos6 icon-novedades"></span>
            <span class="icon-position icon-novedades"></span> Novedades</a>
        </li>
        <!-- <li class="li-accordion" data-aos="fade-up" data-aos-delay="700">
            <a class="link--cuenta" href="#" class="title-accordion"><span class="iconos7 icon-calificacion"></span> Califica nuestros servicios</a>
        </li> -->
    </ul>
</div>

<app-btn-circle></app-btn-circle>
