import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import Swal from 'sweetalert2';
import { ApiCallService } from '../api-call.service';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global.service"

import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare var $: any;

@Component({
  selector: 'app-cuentas-claras-ng',
  templateUrl: './cuentas-claras-ng.component.html',
  styleUrls: ['./cuentas-claras-ng.component.scss']
})

export class CuentasClarasNGComponent implements OnInit {
  //grafico inicio
  public barChartOptions: ChartOptions = {
    responsive: true,
  };

  //select
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  MaquinaUnica: any[] = [];
  maquinaDato: any[] = [];

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins: any;

  public barChartData: ChartDataSets[] = [];
  public barChartLabelsH: Label[] = [];
  public barChartDataH: ChartDataSets[] = [];
  public formatBarColor: Color[] = [];
  colers: any;
  //datos grafico
  year: any[] = [];
  yearB: any[] = [];
  total: any[] = [];
  totalB: any[] = [];
  //actual
  mes: any[] = [];
  totalmensual: any[] = [];
  totalR: any[] = [];
  totalM: any[] = [];
  totalD: any[] = [];
  mesFinal: any[] = [];
  totalmensualFinal: any[] = [];

  //grafico final

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  cuentasCLaras: any;
  public emitidas: Array<any>;
  public porVencer: Array<any>;
  public Vencidas: Array<any>;
  public N_Credito: Array<any>;
  gEmitidas: any[] = [];
  gEmitidasR: any[] = [];
  public maquinas: any[] = [];

  maquinaRespuesta: any[] = [];
  Inversion: any;
  InversionA: any;

  bandera: any;

  public inicio: any;
  public termino: any;
  public estado: any;

  public elemfiltrados: any;

  paginaActual: string = "";

  private CardCode: string;


  constructor(
    private _api: ApiCallService,
    private _router: Router,
    private _routerURL: ActivatedRoute,

  ) {

    this.CardCode = "CN76172201";

    this.emitidas = [
      {
        FacturaDate: "03/05/2021",
        Serie: 25,
        Tipo: "NC - 14765",
        DocNum: 4567,
        Descripcion: "Factura PRUEBA1",
        GastosRepuestos: 500,
        GastosMO: 100,
        Total: 500,
      }, {
        FacturaDate: "04/06/2021",
        Serie: 25,
        Tipo: "NC - 14765",
        DocNum: 4567,
        Descripcion: "Factura PRUEBA2",
        GastosRepuestos: 500,
        GastosMO: 100,
        Total: 1000,
      }, {
        FacturaDate: "01/07/2021",
        Serie: 25,
        Tipo: "NC - 14765",
        DocNum: 4567,
        Descripcion: "Factura PRUEBA3",
        GastosRepuestos: 500,
        GastosMO: 100,
        Total: 1500,
      },
    ]

  }

  anioActual: number = 2020;

  //CuentasClaras
  //FacturasPorVencer
  //FacturasVencidas
  //NotasDeCredito
  //InversionesAnioActual
  //InversionesHistorico



  ngOnInit(): void {
    this.dropdownList = [

    ];
    this.selectedItems = [

    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };



    this.barChartLabels = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    this.barChartData = [
      { data: this.totalmensual, label: 'Total' },
      { data: this.totalR, label: 'Total Repuesto' },
      { data: this.totalM, label: 'Total Mano de obra' },
      { data: this.totalD, label: 'Total Despacho' }

    ]
    //grafico inicio
    this.barChartLabelsH = this.year;
    this.barChartDataH = [
      { data: this.total, label: 'Total' },

    ]
    this.formatBarColor = [
      {
        borderColor: '#3FBF3F',
        backgroundColor: '#3FBF3F',
        pointBackgroundColor: '#3FBF3F',
        pointHoverBackgroundColor: '#3FBF3F',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      },
      {
        borderColor: '#007bff',
        backgroundColor: '#007bff',
        pointBackgroundColor: '#007bff',
        pointHoverBackgroundColor: '#007bff',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      },
      {
        borderColor: '#F3FF00',
        backgroundColor: '#F3FF00',
        pointBackgroundColor: '#F3FF00',
        pointHoverBackgroundColor: '#F3FF00',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      }, {
        borderColor: '#C850C0',
        backgroundColor: '#C850C0',
        pointBackgroundColor: '#C850C0',
        pointHoverBackgroundColor: '#C850C0',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      },
    ]
    //grafico final


    if (this._routerURL.snapshot.paramMap.get("parametro")) {
      this.paginaActual = this._routerURL.snapshot.paramMap.get("parametro");
    }
    else {
      this.paginaActual = "CuentasClaras";

    }


    this.anioActual = (new Date()).getFullYear();

    // this._api.F_Emitidas().subscribe(res => {
    //   // console.log(res);
    //   this.emitidas = res;
    // })

    this.elemfiltrados = this.emitidas;

    // this._api.F_Vencidas().subscribe(res => {
    //   // console.log(res);
    //   this.Vencidas = res;
    // })

    // this._api.F_XVencer().subscribe(res => {
    //   // console.log(res);
    //   this.porVencer = res;
    // })
    /*     this._api.TarjetasDeEquipo().subscribe(res => {
          // console.log(res);
          this.maquinas = res;
          console.log(this.maquinas)
        }) */


    this.emitidas = [];
    this.porVencer = [];
    this.Vencidas = [];
    this.N_Credito = [];
    this.cuentasCLaras = [];





    var today = new Date();
    var fechaAnterior = new Date(today.setMonth(today.getMonth() - 6));
    //console.log("hola");
    this._api.CuentasClaras().subscribe(res => {
      //console.log(res)


      res.forEach(element => {
        var DocDate = element.DocDate.substring(0, 10);

        var fechaDocumento = new Date(element.DocDate);
        //console.log(fechaDocumento);


        var arreglo = DocDate.split('-');
        DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];

        element.DocDate = DocDate;
        element.DocTotal = this.pasaPesosChilenos(element.DocTotal);

        element.REPUESTOS = this.pasaPesosChilenos(element.REPUESTOS);

        element.MANOOBRA = this.pasaPesosChilenos(element.MANOOBRA);

        element.DESPACHO = this.pasaPesosChilenos(element.DESPACHO);


        element.Maquina_descripcion = this.remueveAcento(element.Maquina_descripcion);


        //this.emitidas = [];
        //this.porVencer = [];
        //this.Vencidas = [];
        //this.N_Credito = [];
        if (fechaDocumento >= fechaAnterior) {
          //console.log("asd");


          this.emitidas.push(element);


        }


        if (element.ESTADO_PAGO == "POR VENCER") {
          this.porVencer.push(element);
        }

        if (element.ESTADO_PAGO == "VENCIDA") {
          this.Vencidas.push(element);
        }


        if (element.ORIGEN_ABREVIADO == "NC") {
          this.N_Credito.push(element);
        }

      });
      var today = new Date();
      var year = today.getFullYear();
      console.log(year)

      this.graficar(this.emitidas)
      /* this.graficar(this.emitidas)
            var mes = '';
            var totalR, totalM, totalD = 0;
            var gEmitidasR1, gEmitidasR2, gEmitidasR3, gEmitidasR4, gEmitidasR5, gEmitidasR6, gEmitidasR7, gEmitidasR8, gEmitidasR9, gEmitidasR10, gEmitidasR11, gEmitidasR12 = []
            this.emitidas.forEach(element => {
              //console.log(element)
              var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

              for (let i = 0; i <= 12; i++) {
                //console.log(i)
                if (element.DocDate_MONTH == i && element.DocDate_YEAR == year) {

                  if (mes != meses[i - 1]) { totalR = 0; totalM = 0; totalD = 0; }
                  mes = meses[i - 1]
                  var cambioR = element.REPUESTOS.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
                  var numero = Number(cambioR)
                  totalR = numero + totalR
                  var cambioM = element.MANOOBRA.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
                  var numeroM = Number(cambioM)
                  totalM = numeroM + totalM
                  var cambioD = element.DESPACHO.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
                  var numeroD = Number(cambioD)
                  totalD = numeroD + totalD
                }

              }

              if (mes == "Enero") {
                gEmitidasR1 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Febrero") {
                gEmitidasR2 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Marzo") {
                gEmitidasR3 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Abril") {
                gEmitidasR4 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Mayo") {
                gEmitidasR5 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Junio") {
                gEmitidasR6 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Julio") {
                gEmitidasR7 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Agosto") {
                gEmitidasR8 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Septiembre") {
                gEmitidasR9 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Octubre") {
                gEmitidasR10 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Noviembre") {
                gEmitidasR11 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              } else if (mes == "Diciembre") {
                gEmitidasR12 = ([{ "mes": mes, "totalR": totalR, "totalM": totalM, "totalD": totalD }])
              }
            });

            this.gEmitidas = [gEmitidasR1, gEmitidasR2, gEmitidasR3, gEmitidasR4, gEmitidasR5, gEmitidasR6, gEmitidasR7, gEmitidasR8, gEmitidasR9, gEmitidasR10, gEmitidasR11, gEmitidasR12]
            console.log(this.gEmitidas)
            this.gEmitidas.forEach(a => {
              console.log(a)
              this.totalR.push(a[0].totalR);
              this.totalM.push(a[0].totalM);
              this.totalD.push(a[0].totalD);
            }) */

      const key = 'Maquina';

      this.MaquinaUnica = [...new Map(res.map(item =>
        [item[key], item]
      )).values()];

      //console.log(this.MaquinaUnica);
      this.MaquinaUnica.forEach(aa => {
        if (aa.Maquina == "" || aa.Maquina == null) {
          this.maquinaDato.push({ item_id: aa.Maquina, item_text: "Sin Maquina Asociada" })
        } else {
          this.maquinaDato.push({ item_id: aa.Maquina, item_text: aa.Maquina_descripcion })
        }

      })
      this.dropdownList = this.maquinaDato
      //console.log(this.emitidas)

      //TODO
      this.cuentasCLaras = res;

      this.emitidas.sort((a, b) => {
        return new Date(b.TaxDate).getTime() - new Date(a.TaxDate).getTime();
      });

    })



    this._api.InversionAgrupado().subscribe(res => {
      //console.log(res)
      this.Inversion = res;
      res.forEach(element => {
        this.year.push(element.anio)

        this.total.push(element.total)

      })

      //   console.log(this.year)
      console.log(this.total)

    })

    var today = new Date();
    var year = today.getFullYear();

    this._api.InversionActual(year).subscribe(res => {
      if (res) {
        //console.log(res)
        this.InversionA = res;
        /*             var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                    var indexmes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                    var ultimo = 0
                    res.forEach(element => {
                      var mm = 12 - element.mes + ultimo
                      var antes = 12 - mm
                      //console.log(antes)
                      for (let i = 1; i < antes; i++) {
                        this.totalmensual.push('')
                      }

                      ultimo = element.mes
                      indexmes.forEach(item => {
                        //console.log('dato res', element.mes, '   index', indexmes[item - 1])
                        if (element.mes == item) {
                          //console.log('mes con dato', meses[item - 1])
                          this.mes.push(meses[item - 1])
                          this.totalmensual.push(element.totalM)

                        }

                      })

                    })*/


        //console.log(x)
        //console.log(this.mes);
        //console.log(this.totalmensual);
      }

    })
    this.bandera = false;



  }

  remueveAcento(text) {
    // text = text.toLowerCase();
    // text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    // text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    // text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    // text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    // text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    // text = text.replace(new RegExp('[Ç]','gi'), 'c');
    return text;
  }
  graficar(item) {
    console.log("hola", item)
    console.log('tr', this.totalR)

    var today = new Date();
    var year = today.getFullYear();
    console.log(year)
    var mes;
    var totalR, totalM, totalD, totalT;
    var gEmitidasR1, gEmitidasR2, gEmitidasR3, gEmitidasR4, gEmitidasR5, gEmitidasR6, gEmitidasR7, gEmitidasR8, gEmitidasR9, gEmitidasR10, gEmitidasR11, gEmitidasR12;
    item.forEach(element => {
      //console.log(element)
      var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      for (let i = 0; i <= 12; i++) {
        //console.log(i)
        if (element.DocDate_MONTH == i && element.DocDate_YEAR == year) {

          if (mes != meses[i - 1]) { totalR = 0; totalM = 0; totalD = 0; totalT = 0; }
          mes = meses[i - 1]
          var cambioR = element.REPUESTOS.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
          var numero = Number(cambioR)
          totalR = numero + totalR
          var cambioM = element.MANOOBRA.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
          var numeroM = Number(cambioM)
          totalM = numeroM + totalM
          var cambioD = element.DESPACHO.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
          var numeroD = Number(cambioD)
          totalD = numeroD + totalD

          var cambioT = element.DocTotal.replace("$", "").replace(".", "").replace(".", "").replace(".", "")
          var numeroT = Number(cambioT)
          totalT = numeroT + totalT
        }

      }

      if (mes == "Enero") {
        gEmitidasR1 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Febrero") {
        gEmitidasR2 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Marzo") {
        gEmitidasR3 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Abril") {
        gEmitidasR4 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Mayo") {
        gEmitidasR5 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Junio") {
        gEmitidasR6 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Julio") {
        gEmitidasR7 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Agosto") {
        gEmitidasR8 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Septiembre") {
        gEmitidasR9 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Octubre") {
        gEmitidasR10 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Noviembre") {
        gEmitidasR11 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      } else if (mes == "Diciembre") {
        gEmitidasR12 = ({ "mes": mes, "totalT": totalT, "totalR": totalR, "totalM": totalM, "totalD": totalD });
      }
    });

    this.gEmitidas = [gEmitidasR1, gEmitidasR2, gEmitidasR3, gEmitidasR4, gEmitidasR5, gEmitidasR6, gEmitidasR7, gEmitidasR8, gEmitidasR9, gEmitidasR10, gEmitidasR11, gEmitidasR12]
    console.log('gemi', this.gEmitidas)
    this.gEmitidas.forEach(res => {
      if (res) {
        console.log('a', res)
        this.totalmensual.push(res.totalT)
        this.totalR.push(res.totalR);
        this.totalM.push(res.totalM);
        this.totalD.push(res.totalD);
      }
      console.log('total ', this.totalmensual)
    })
  }

  AbreMenu() {

    let menu = document.getElementById("menu");
    menu.classList.toggle("change-menu");
    menu.classList.toggle("change");

    document.getElementsByClassName("wrapper-mask").item(0).classList.toggle("opacidad");

    document.getElementsByClassName("panel-right-emergent").item(0).classList.toggle("mostrar-panel-right");
    document.getElementById("ver-animation").classList.toggle("animate__animated animate__fadeInRight");



  }

  AbrirFiltro() {
    document.getElementsByClassName("wrapper-mask").item(0).classList.toggle("opacidad");
    document.getElementsByClassName("panel-left-emergent").item(0).classList.toggle("mostrar-panel-left");
    document.getElementById("left-animation").classList.toggle("animate__animated animate__fadeInLeft");


  }

  cambioElemento(pagina: string, origen: string) {
    //console.log(pagina)
    this.paginaActual = pagina;
    this._router.navigate(["/cuentasClaras/" + pagina]);
    if (pagina == "InversionesAnioActual") {
      // Any of the following formats may be used

      //console.log("invercion actual")
      if (this.InversionA.length == 0) {
        Swal.fire({
          title: 'En este año no figura inversion',
          text: "",
          icon: 'warning',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

            this.paginaActual = "CuentasClaras";

          }
        })

      }
    }
    if (pagina == "InversionesHistorico") {
      //console.log("histo")
      //console.log(this.Inversion);
      if (this.Inversion.length == 0) {
        Swal.fire({
          title: 'No figura inversion',
          text: "",
          icon: 'warning',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {

            this.paginaActual = "CuentasClaras";

          }
        })

      }

    }
    if (origen != 'grafico') {
      this.AbreMenu();
    }

  }

  filtro() {

    this.bandera = false;

    if (this.inicio && !this.termino) {
      //console.log("ingrese termino")
      var fecha = new Date()
      //console.log(fecha)
      /*       var dia = fecha.getDay().toString()
            var mes = fecha.getMonth().toString()
            var anio = fecha.getFullYear()
            if (dia.length == 1) {
              dia = "0" + dia
            }
            if (mes.length == 1) {
              mes = "0" + mes
            }
            this.termino = anio + "-" + mes + "-" + dia; */
      this.termino = new Date();
    }

    if (!this.inicio && this.termino) {
      //console.log("ingrese inicio")
      var today = new Date();
      this.inicio = new Date(today.setMonth(today.getMonth() - 6));
    }

    if (this.inicio && this.termino) {
      //console.log('inicio', this.inicio, '  termino', this.termino)
    }

    if (this.selectedItems) {
      //console.log("estas son las maquinas", this.selectedItems);
    }

    if (this.estado) {
      //console.log('estado', this.estado)
    }
    //console.log('inicio', this.inicio, '  termino', this.termino)
    if (this.inicio != null && this.termino != null && this.selectedItems.length == 0) {
      console.log('solo fechas', this.inicio, this.termino)
      this.emitidas = [];
      this.porVencer = [];
      this.Vencidas = [];
      this.N_Credito = [];

      this._api.CuentasClaras().subscribe(res => {
        res.forEach(element => {
          var DocDate = element.DocDate.substring(0, 10);

          var fechaDocumento = new Date(element.DocDate);
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fetater = new Date(this.termino + " 00:00:00")
          console.log(fechaDocumento, fechaini, fetater);


          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];

          element.DocDate = DocDate;
          element.DocTotal = this.pasaPesosChilenos(element.DocTotal);
          element.REPUESTOS = this.pasaPesosChilenos(element.REPUESTOS);
          element.MANOOBRA = this.pasaPesosChilenos(element.MANOOBRA);
          element.DESPACHO = this.pasaPesosChilenos(element.DESPACHO);

          element.test = "TEST NAZI"

          element.Maquina_descripcion = this.remueveAcento(element.Maquina_descripcion);

          //this.emitidas = [];
          //this.porVencer = [];
          //this.Vencidas = [];
          //this.N_Credito = [];
          //console.log(fechaDocumento)
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fetater) {
              //console.log(fechaDocumento)

              this.emitidas.push(element);

              if (element.ESTADO_PAGO == "POR VENCER") {
                this.porVencer.push(element);
              }

              if (element.ESTADO_PAGO == "VENCIDA") {
                this.porVencer.push(element);
              }


              if (element.ORIGEN_ABREVIADO == "NC") {
                this.N_Credito.push(element);
              }

            }
          }
        });

        //TODO
        this.cuentasCLaras = res;



      })

    }
    if (this.inicio != null && this.termino != null && this.selectedItems.length >= 1) {
      console.log(' fechas y maquina')
      this.emitidas = [];
      this.porVencer = [];
      this.Vencidas = [];
      this.N_Credito = [];
      this._api.CuentasClaras().subscribe(res => {
        res.forEach(element => {
          var DocDate = element.DocDate.substring(0, 10);

          var fechaDocumento = new Date(element.DocDate);
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fetater = new Date(this.termino + " 00:00:00")
          //console.log(fechaDocumento);


          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];

          element.DocDate = DocDate;
          element.DocTotal = this.pasaPesosChilenos(element.DocTotal);
          element.REPUESTOS = this.pasaPesosChilenos(element.REPUESTOS);
          element.MANOOBRA = this.pasaPesosChilenos(element.MANOOBRA);
          element.DESPACHO = this.pasaPesosChilenos(element.DESPACHO);


          element.Maquina_descripcion = this.remueveAcento(element.Maquina_descripcion);

          //this.emitidas = [];
          //this.porVencer = [];
          //this.Vencidas = [];
          //this.N_Credito = [];
          //console.log(fechaDocumento)
          this.selectedItems.forEach(itemMaquina => {
            if (fechaDocumento >= fechaini) {
              if (fechaDocumento <= fetater) {
                //console.log(fechaDocumento)
                if (element.Maquina == itemMaquina.item_id) {
                  this.emitidas.push(element);

                  if (element.ESTADO_PAGO == "POR VENCER") {
                    this.porVencer.push(element);
                  }

                  if (element.ESTADO_PAGO == "VENCIDA") {
                    this.porVencer.push(element);
                  }


                  if (element.ORIGEN_ABREVIADO == "NC") {
                    this.N_Credito.push(element);
                  }

                }

              }
            }
          })
        });

        //TODO
        this.cuentasCLaras = res;


      })
    }
    if (this.inicio == null && this.termino == null && this.selectedItems.length >= 1) {
      console.log(' solo maquina')
      this.emitidas = [];
      this.porVencer = [];
      this.Vencidas = [];
      this.N_Credito = [];
      this._api.CuentasClaras().subscribe(res => {
        res.forEach(element => {
          var DocDate = element.DocDate.substring(0, 10);

          var fechaDocumento = new Date(element.DocDate);
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fetater = new Date(this.termino + " 00:00:00")
          //console.log(fechaDocumento);


          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];

          element.DocDate = DocDate;
          element.DocTotal = this.pasaPesosChilenos(element.DocTotal);
          element.REPUESTOS = this.pasaPesosChilenos(element.REPUESTOS);
          element.MANOOBRA = this.pasaPesosChilenos(element.MANOOBRA);
          element.DESPACHO = this.pasaPesosChilenos(element.DESPACHO);


          element.Maquina_descripcion = this.remueveAcento(element.Maquina_descripcion);

          //this.emitidas = [];
          //this.porVencer = [];
          //this.Vencidas = [];
          //this.N_Credito = [];
          //console.log(fechaDocumento)
          this.selectedItems.forEach(itemMaquina => {
            /*             if (fechaDocumento >= fechaini) {
                          if (fechaDocumento <= fetater) { */
            //console.log(fechaDocumento)
            if (element.Maquina == itemMaquina.item_id) {
              this.emitidas.push(element);

              if (element.ESTADO_PAGO == "POR VENCER") {
                this.porVencer.push(element);
              }

              if (element.ESTADO_PAGO == "VENCIDA") {
                this.porVencer.push(element);
              }


              if (element.ORIGEN_ABREVIADO == "NC") {
                this.N_Credito.push(element);
              }

            }

            /*               }
                        } */
          })
        });

        //TODO
        this.cuentasCLaras = res;


      })
    }
    this.AbrirFiltro();

    this.emitidas.sort((a, b) => {
      return new Date(b.TaxDate).getTime() - new Date(a.TaxDate).getTime();
    });

  }
  quitarfiltro() {
    if (this.inicio == null && this.termino == null && this.selectedItems.length == 0) {
      console.log("sin filtro anterior");
    } else {
      console.log("limpia y recarga datos original");
      this.inicio = null;
      this.termino = null;
      this.selectedItems = [];
      this.emitidas = [];
      this.porVencer = [];
      this.Vencidas = [];
      this.N_Credito = [];
      this.cuentasCLaras = [];





      var today = new Date();
      var fechaAnterior = new Date(today.setMonth(today.getMonth() - 6));
      //console.log("hola");
      this._api.CuentasClaras().subscribe(res => {
        res.forEach(element => {
          var DocDate = element.DocDate.substring(0, 10);

          var fechaDocumento = new Date(element.DocDate);
          //console.log(fechaDocumento);


          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];

          element.DocDate = DocDate;
          element.DocTotal = this.pasaPesosChilenos(element.DocTotal);
          element.REPUESTOS = this.pasaPesosChilenos(element.REPUESTOS);
          element.MANOOBRA = this.pasaPesosChilenos(element.MANOOBRA);
          element.DESPACHO = this.pasaPesosChilenos(element.DESPACHO);

          element.Maquina_descripcion = this.remueveAcento(element.Maquina_descripcion);


          //this.emitidas = [];
          //this.porVencer = [];
          //this.Vencidas = [];
          //this.N_Credito = [];
          if (fechaDocumento >= fechaAnterior) {
            //console.log("asd");


            this.emitidas.push(element);


          }


          if (element.ESTADO_PAGO == "POR VENCER") {
            this.porVencer.push(element);
          }

          if (element.ESTADO_PAGO == "VENCIDA") {
            this.porVencer.push(element);
          }


          if (element.ORIGEN_ABREVIADO == "NC") {
            this.N_Credito.push(element);
          }


        });

        //TODO
        this.cuentasCLaras = res;


      },(error)=>{
        console.log(error);
      })
    }

    this.AbrirFiltro();

    this.emitidas.sort((a, b) => {
      return new Date(b.TaxDate).getTime() - new Date(a.TaxDate).getTime();
    });

  }
  btnCerrar() {
    $(".wrapper-overlay").hide();
  }


  verObservaciones(mensaje) {
    Swal.fire(mensaje);
  }

  /*   pasaPesosChilenos(money) {
      var moneda = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'CLP' }).format(money).toString();
      //console.log(moneda);
      var texto = moneda.toString().replace("CLP", "$");
      texto = texto.replace(",", ".");
      texto = texto.replace(",", ".");
      texto = texto.replace(",", ".");
      texto = texto.replace(",", ".");
      //console.log(texto);
      return texto;
    } */
  pasaPesosChilenos(money) {
    var moneda = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(money).toString();
    //console.log(moneda);

    //console.log(texto);
    return moneda;
  }
  cambio(item: any) {
    this.maquinaRespuesta = []
    //console.log(item);
    this.maquinaRespuesta.push(item)
    //console.log('inicio', this.inicio, '  termino', this.termino)
  }


  AbrirPDFMaquina(item) {
    if (item.U_INDS_IT_PDF_URL) {
      this._router.navigate(["/verPdf", item.U_INDS_IT_PDF_URL]);
    }
    else {
      Swal.fire("Maquina no cuenta con ficha tecnica");
    }
  }

  AbrirInformeTecnico(item) {
    console.log(item)
    if (item.ADJUNTO_LLAMADA) {
      console.log('asd',item.ADJUNTO_LLAMADA)
      this._router.navigate(["/verPdf", 'https://taglerpsapi.indeone.app/Viewer/View/?path=' + item.ADJUNTO_LLAMADA]);
    }
    else {
      Swal.fire("Llamada de servicio no cuenta con informe tecnico");
    }
  }

  AbrirHistorico(item) {
    if (item.Serie) {
      this._router.navigate(["/historico", item.Maquina, item.Serie]);
    }
    else {
      Swal.fire("Documento no cuenta con maquina relacionada");
    }
  }


  AbrirDTE(item) {
    var folio = item.FolioNum;
    var indicador = item.FolioPref;
    var urlFinal = GlobalService.urlAPI + "Viewer/viewDTE?folio=" + folio + "&Indicator=" + indicador;
    this._router.navigate(["/verPdf", urlFinal]);
  }


  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        const value2 = chart.data.datasets[1].data[clickedElementIndex];
        const value3 = chart.data.datasets[1].data[clickedElementIndex];
        console.log(clickedElementIndex, label, value, value2, value3)

        var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        for (let i = 0; i < 12; i++) {
          if (label == meses[i]) {
            var today = new Date();
            var year = today.getFullYear();
            var mes = i + 1
            var dia = { "Enero": 31, "Febrero": 28, "Marzo": 31, "Abril": 30, "Mayo": 31, "Junio": 30, "Julio": 31, "Agosto": 31, "Septiembre": 30, "Octubre": 31, "Noviembre": 30, "Diciembre": 31 }
            console.log(label, mes)
            this.inicio = mes + '/' + 1 + '/' + year;
            this.termino = mes + '/' + 31 + '/' + year;
            console.log(this.inicio, this.termino)
            this.filtroGrafico()
            this.cambioElemento('CuentasClaras', 'grafico')

          }
        }
      }

    }
  }
  filtroGrafico() {
    if (this.inicio != null && this.termino != null && this.selectedItems.length == 0) {
      //console.log('solo fechas', this.inicio, this.termino)
      this.emitidas = [];
      this.porVencer = [];
      this.Vencidas = [];
      this.N_Credito = [];
      this._api.CuentasClaras().subscribe(res => {
        res.forEach(element => {
          var DocDate = element.DocDate.substring(0, 10);

          var fechaDocumento = new Date(element.DocDate);
          var fechaini = new Date(this.inicio + " 00:00:00")
          var fetater = new Date(this.termino + " 00:00:00")
          //console.log(fechaDocumento, fechaini, fetater);


          var arreglo = DocDate.split('-');
          DocDate = arreglo[2] + "/" + arreglo[1] + "/" + arreglo[0];

          element.DocDate = DocDate;
          element.DocTotal = this.pasaPesosChilenos(element.DocTotal);
          element.REPUESTOS = this.pasaPesosChilenos(element.REPUESTOS);
          element.MANOOBRA = this.pasaPesosChilenos(element.MANOOBRA);
          element.DESPACHO = this.pasaPesosChilenos(element.DESPACHO);


          element.Maquina_descripcion = this.remueveAcento(element.Maquina_descripcion);

          //this.emitidas = [];
          //this.porVencer = [];
          //this.Vencidas = [];
          //this.N_Credito = [];
          //console.log(fechaDocumento)
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fetater) {
              //console.log(fechaDocumento)

              this.emitidas.push(element);

              if (element.ESTADO_PAGO == "POR VENCER") {
                this.porVencer.push(element);
              }

              if (element.ESTADO_PAGO == "VENCIDA") {
                this.porVencer.push(element);
              }


              if (element.ORIGEN_ABREVIADO == "NC") {
                this.N_Credito.push(element);
              }

            }
          }
        });

        //TODO
        this.cuentasCLaras = res;


      })

    }

    this.emitidas.sort((a, b) => {
      return new Date(b.TaxDate).getTime() - new Date(a.TaxDate).getTime();
    });
  }

}
