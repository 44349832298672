import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiCallService } from '../api-call.service';
import Swal from 'sweetalert2'
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from '../global.service';


declare var $: any;

@Component({
  selector: 'app-solicitud-de-servicio',
  templateUrl: './solicitud-de-servicio.component.html',
  styleUrls: ['./solicitud-de-servicio.component.scss']
})
export class SolicitudDeServicioComponent implements OnInit {
  loginForm: FormGroup;
  public maquinas: Array<any>;
  maquinasSelecionada: any;
  maquinasSelecionada2: any;

  serviciosPendientes: any;
  serviciosEnCurso: any;
  historicoSolcitudes: any;

  //select
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  MaquinaUnica: any[] = [];
  maquinaDato: any[] = [];

  public inicio: any;
  public termino: any;
  //filtro
  bandera: any;
  public estado: any;
//perfil
  Perfil: any;
  constructor(
    private _api: ApiCallService,
    private _formBuilder: FormBuilder,
    private _cookieService: CookieService,
    private _router: Router,
    private _routerURL: ActivatedRoute,
  ) { }

  paginaActual: string;

  //SolicitudDeServicio
  //ServiciosPendientes
  //ServiciosEnCurso
  //HistoricoSolicitudes



  ngOnInit(): void {
    this.Perfil = this._cookieService.get('perfil');
    /* Perfil: Gold, Platinum, Silver, Global */

    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar Todo',
      unSelectAllText: 'Deseleccionar Todo',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };


    if(this._routerURL.snapshot.paramMap.get("parametro"))
    {
      this.paginaActual = this._routerURL.snapshot.paramMap.get("parametro");
    }
    else
    {
      this.paginaActual = "SolicitudDeServicio";

    }
    


    this.loginForm = this._formBuilder.group({
      tipoAsistencia: ['', Validators.required],
      Solicitud: ['', Validators.required],
    });

    /*     this._api.Mis_Maquinas('CN76172201').subscribe(res=>
          {
             console.log(res);
            this._api.Mis_Maquinas = res;
          }) */
      var arregloIMgen = [];

      //arregloIMgen.push({imagen: "https://www.taglermaq.cl/wp-content/uploads/2021/04/new-maq1.png"});
      //arregloIMgen.push({imagen: "https://www.taglermaq.cl/wp-content/themes/taglermaq/img/maq.png"});
      //arregloIMgen.push({imagen: "https://www.taglermaq.cl/wp-content/uploads/2021/04/new-mac2.png"});
      arregloIMgen.push({imagen: GlobalService.imageNotFound});
      console.log(arregloIMgen);
  
      this._api.TarjetasDeEquipo().subscribe(res=>
        {
          // console.log(res);
          res.forEach(element => {
            if (!element.U_URL_Imagen_SKU)
            {
              //https://www.taglermaq.cl/wp-content/uploads/2021/04/new-maq1.png
              //https://www.taglermaq.cl/wp-content/themes/taglermaq/img/maq.png
              //https://www.taglermaq.cl/wp-content/uploads/2021/04/new-mac2.png
  
              var alAzar = parseInt((Math.random() * arregloIMgen.length).toString(), 0);
            
              element.U_URL_Imagen_SKU = arregloIMgen[alAzar].imagen;
            }
          });
          this.maquinas= res;        
        })

    this.cargaServicios();
  }

  SeleccionMaquina(item) {

    //console.log("Cargando datos de maquina" , item);
    this.maquinasSelecionada = item.insID;
    this.maquinasSelecionada2 = item;
    console.log(this.maquinasSelecionada.insID)
    document.getElementById("exampleFormControlTextarea1").focus();

    document.getElementById("divMaquinaSeleccionada").style.removeProperty("display");
    this.btnCerrarBuscarMaquina();

  }

  btnBuscarMaquina() {
    $(".wrapper-overlay").show();
  }

  BuscarMaquina() {
    let textvalue = (<HTMLInputElement>document.getElementById("buscar")).value;
    textvalue = textvalue.toUpperCase()
    console.log(textvalue)
    if (textvalue != "") {
      this.maquinas = [];
      this._api.TarjetasDeEquipo().subscribe(res => {
        //console.log(res);
        var Modelo = "";
        var Nombre = "";
        var Marca = "";
        var Serie = "";
        var itemCode = "";
        res.forEach(element => {
          if (element.ItemName) {
            Nombre = element.ItemName.toUpperCase();
          }
          if (element.Marca) {
            Marca = element.Marca.toUpperCase();
          }

          if (element.U_Modelo) {
            Modelo = element.U_Modelo.toUpperCase();
          }
          if (element.internalSN) {
            Serie = element.internalSN.toUpperCase();
          }
          if (element.itemCode) {
            itemCode = element.itemCode.toUpperCase();
          }

          if (Nombre.includes(textvalue) || Marca.includes(textvalue) || Modelo.includes(textvalue) || Serie.includes(textvalue) || itemCode.includes(textvalue)) {
            this.maquinas.push(element)
          }
        });
      })
    } else {
      this._api.TarjetasDeEquipo().subscribe(res => {
        //console.log(res);
        this.maquinas = res;
      })

    }

  }


  btnCerrarBuscarMaquina() {
    $(".wrapper-overlay").hide();
  }



  AbreMenu() {

    let menu = document.getElementById("menu");
    menu.classList.toggle("change-menu");
    menu.classList.toggle("change");

    document.getElementsByClassName("panel-right-emergent").item(0).classList.toggle("mostrar-panel-right");
    document.getElementById("ver-animation").classList.toggle("animate__animated animate__fadeInRight");
  }

  AbrirFiltro() {

    document.getElementsByClassName("panel-left-emergent").item(0).classList.toggle("mostrar-panel-left");
    document.getElementById("left-animation").classList.toggle("animate__animated animate__fadeInLeft");
  }
  cambioElemento(pagina: string) {
    this._router.navigate(["/solicitudDeServicio/" + pagina]);
    this.paginaActual = pagina;
    this.AbreMenu();

  }


  generarSolicitud() {
    console.log(this.loginForm.value);



    if (this.loginForm.value.tipoAsistencia) {
      if (this.loginForm.value.Solicitud) {
        if (this.maquinasSelecionada2) {
          var ItemCode = this.maquinasSelecionada2.itemCode;
          var Serie = this.maquinasSelecionada2.internalSN;
          var CardCode = this.maquinasSelecionada2.customer;
          var ItemName = this.maquinasSelecionada2.ItemName;
          var PERIODO_SERVICIO = this.loginForm.value.tipoAsistencia;
          var SOLICITUD = this.loginForm.value.Solicitud;
          //console.log("maquina", this.maquinasSelecionada2);
          //console.log("form", this.loginForm);
          this._api.SOLICITUD_SERVICIO(PERIODO_SERVICIO, SOLICITUD, CardCode, ItemCode, ItemName, Serie)
            .subscribe(res => {
              if (res.success) {
                this.cargaServicios();
                Swal.fire({
                  title: 'Servicio',
                  text: res.message,
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `OK`,
                  icon: "success",
                  allowEscapeKey: false,
                  allowOutsideClick: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    var dirtyFormID = 'myForm';
                    var resetForm = <HTMLFormElement>document.getElementById(dirtyFormID);
                    resetForm.reset();
                    this.paginaActual = "ServiciosPendientes";
                  }
                })
              }
              else {
                Swal.fire(
                  'Servicio',
                  res.message,
                  'error'
                )
              }

            });
        }
        else {
          Swal.fire(
            'Servicio',
            "Seleccione maquina",
            'warning'
          )
        }
      }
      else {

        Swal.fire(
          'Servicio',
          "Ingrese Solicitud",
          'warning'
        )
      }
    }
    else {
      Swal.fire(
        'Servicio',
        "Seleccione un servicio de asistencia técnica",
        'warning'
      )
    }

  }


  cargaServicios() {
    this.serviciosPendientes = [];
    this.serviciosEnCurso = [];
    this.historicoSolcitudes = [];
    this._api.ObtieneServiciosTodos().subscribe(res => {
      console.log(res)


      res.forEach(item => {

        if (!item.F_ASISTENCIA_TECNICA) {
          item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
        }

        var abierto = true;

        if (item.status_Name == "Pendiente") {
          this.serviciosPendientes.push(item);
        }
        else if (item.status_Name == "Abierto") {
          this.serviciosEnCurso.push(item);
        }
        else {
          var abierto = false;
        }

        item.estado = abierto;
        this.historicoSolcitudes.push(item)
      });
      console.log(this.historicoSolcitudes);

      const key = 'ItemCode';

      this.MaquinaUnica = [...new Map(res.map(item =>
        [item[key], item]
      )).values()];

      console.log(this.MaquinaUnica);
      this.MaquinaUnica.forEach(aa => {
        console.log(aa.ItemCode)
        console.log(aa.ItemName)
        if (aa.ItemCode == "" || aa.ItemCode == null) {
          this.maquinaDato.push({ item_id: aa.ItemCode, item_text: "Sin Maquina Asociada" })
        } else {
          this.maquinaDato.push({ item_id: aa.ItemCode, item_text: aa.ItemName })
        }

      })
      console.log(this.maquinaDato)
      this.dropdownList = this.maquinaDato
    });
  }


  verObservaciones(mensaje) {
    Swal.fire(mensaje)
  }


  filtro() {

    this.bandera = false;

    if (this.inicio && !this.termino) {
      //console.log("ingrese termino")
      var fecha = new Date()
      this.termino = new Date();
    }

    if (!this.inicio && this.termino) {
      //console.log("ingrese inicio")
      var today = new Date();
      this.inicio = new Date(today.setMonth(today.getMonth() - 6));
    }

    if (this.inicio && this.termino) {
      //console.log('inicio', this.inicio, '  termino', this.termino)
    }

    if (this.selectedItems) {
      //console.log("estas son las maquinas", this.selectedItems);
    }
    console.log('estado', this.estado)
    if (this.estado) {
      console.log('estado', this.estado)
      if (this.estado == 1) {
        this.estado = true
      }
      if (this.estado == 2) {
        this.estado = false
      }
      if (this.estado == 3) {
        this.estado = "TODO"
      }
    }
    //console.log('inicio', this.inicio, '  termino', this.termino)

    //solo fechas
    if (this.inicio != null && this.termino != null && this.selectedItems.length == 0 && this.estado == null) {
      console.log('solo fechas')
      var fechaini = new Date(this.inicio)
      var fechater = new Date(this.termino)
/*       var fechaAAA = new Date(this.inicio);
      var fechaini = fechaAAA.toISOString();
      var fechaBBB = new Date(this.termino);
      var fechater = fechaBBB.toISOString(); */
      console.log(" fecha inicial", fechaini, '  fecha termino', fechater)
      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {


          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;
          var fechaDo = item.F_SOLICITUD.substring(6,10)+"-"+item.F_SOLICITUD.substring(3,5)+"-"+item.F_SOLICITUD.substring(0,2)
          var fechaDocumento = new Date(fechaDo)
         // var fechaDocumento = fecha.toISOString()
          console.log('iso fecha', fechaDocumento)

          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fechater) {

              if (item.status_Name == "Pendiente") {
                this.serviciosPendientes.push(item);
              }
              else if (item.status_Name == "Abierto") {
                this.serviciosEnCurso.push(item);
              }
              else {
                var abierto = false;
              }

              item.estado = abierto;
              this.historicoSolcitudes.push(item)
            }
          }
        });

        console.log('serviciosPendientes',this.serviciosPendientes);
        console.log('serviciosEnCurso',this.serviciosEnCurso);
        console.log('historicoSolcitudes',this.historicoSolcitudes);

      });
    }
    //fecha y estado
    if (this.inicio != null && this.termino != null && this.selectedItems.length == 0 && this.estado != null) {
      console.log('fechas y estado')
      var fechaini = new Date(this.inicio)
      var fechater = new Date(this.termino)
/*       var fechaAAA = new Date(this.inicio);
      var fechaini = fechaAAA.toISOString();
      var fechaBBB = new Date(this.termino);
      var fechater = fechaBBB.toISOString(); */

      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;
          var fechaDo = item.F_SOLICITUD.substring(6,10)+"-"+item.F_SOLICITUD.substring(3,5)+"-"+item.F_SOLICITUD.substring(0,2)
          var fechaDocumento = new Date(fechaDo)
          //var fechaDocumento = fechaDo.toISOString();
          console.log('fecha sin medificar', item.F_ASISTENCIA_TECNICA, '  iso fecha', fechaDocumento)
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fechater) {

              if (item.status_Name == "Pendiente") {
                this.serviciosPendientes.push(item);
              }
              else if (item.status_Name == "Abierto") {
                this.serviciosEnCurso.push(item);
              }
              else {
                var abierto = false;
              }

              item.estado = abierto;
              if (this.estado == true || this.estado == false) {
                if (item.estado == this.estado) {
                  this.historicoSolcitudes.push(item)
                }
              } else if (this.estado == "TODO") {
                this.historicoSolcitudes.push(item)
              }
            }
          }
        });

        console.log(this.historicoSolcitudes);

      });
    }
    //fhecha y maquina
    if (this.inicio != null && this.termino != null && this.selectedItems.length >= 1 && this.estado == null) {
      console.log(' fechas y maquina')

      var fechaini = new Date(this.inicio)
      var fechater = new Date(this.termino)
/*       var fechaAAA = new Date(this.inicio);
      var fechaini = fechaAAA.toISOString();
      var fechaBBB = new Date(this.termino);
      var fechater = fechaBBB.toISOString(); */

      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;
          var fechaDo = item.F_SOLICITUD.substring(6,10)+"-"+item.F_SOLICITUD.substring(3,5)+"-"+item.F_SOLICITUD.substring(0,2)
          var fechaDocumento = new Date(fechaDo)
          //var fechaDocumento = fechaDo.toISOString();
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fechater) {
              this.selectedItems.forEach(itemMaquina => {
                if (item.ItemCode == itemMaquina.item_id) {
                  if (item.status_Name == "Pendiente") {
                    this.serviciosPendientes.push(item);
                  }
                  else if (item.status_Name == "Abierto") {
                    this.serviciosEnCurso.push(item);
                  }
                  else {
                    var abierto = false;
                  }

                  item.estado = abierto;

                  this.historicoSolcitudes.push(item)
                }
              })
            }
          }
        });

        console.log(this.historicoSolcitudes);

      });
    }
    //fecha, maquina y estado
    if (this.inicio != null && this.termino != null && this.selectedItems.length >= 1 && this.estado != null) {
      console.log(' fechas, maquina y estado')

      var fechaini = new Date(this.inicio)
      var fechater = new Date(this.termino)
/*       var fechaAAA = new Date(this.inicio);
      var fechaini = fechaAAA.toISOString();
      var fechaBBB = new Date(this.termino);
      var fechater = fechaBBB.toISOString(); */

      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;
          var fechaDo = item.F_SOLICITUD.substring(6,10)+"-"+item.F_SOLICITUD.substring(3,5)+"-"+item.F_SOLICITUD.substring(0,2)
          //var fechaDo = item.F_ASISTENCIA_TECNICA.substring(6,10)+"-"+item.F_ASISTENCIA_TECNICA.substring(3,5)+"-"+item.F_ASISTENCIA_TECNICA.substring(0,2)
          var fechaDocumento = new Date(fechaDo)
          //var fechaDocumento = fechaDo.toISOString();
          if (fechaDocumento >= fechaini) {
            if (fechaDocumento <= fechater) {
              this.selectedItems.forEach(itemMaquina => {
                if (item.ItemCode == itemMaquina.item_id) {
                  if (item.status_Name == "Pendiente") {
                    this.serviciosPendientes.push(item);
                  }
                  else if (item.status_Name == "Abierto") {
                    this.serviciosEnCurso.push(item);
                  }
                  else {
                    var abierto = false;
                  }

                  item.estado = abierto;
                  if (this.estado == true || this.estado == false) {
                    if (item.estado == this.estado) {
                      this.historicoSolcitudes.push(item)
                    }
                  } else if (this.estado == "TODO") {
                    this.historicoSolcitudes.push(item)
                  }
                }
              })
            }
          }
        });

        console.log(this.historicoSolcitudes);

      });
    }
    //solo maquina
    if (this.inicio == null && this.termino == null && this.selectedItems.length >= 1 && this.estado == null) {
      console.log(' solo maquina')

      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;

          this.selectedItems.forEach(itemMaquina => {
            if (item.ItemCode == itemMaquina.item_id) {
              if (item.status_Name == "Pendiente") {
                this.serviciosPendientes.push(item);
              }
              else if (item.status_Name == "Abierto") {
                this.serviciosEnCurso.push(item);
              }
              else {
                var abierto = false;
              }

              item.estado = abierto;
              this.historicoSolcitudes.push(item)

            }
          })

        });

        console.log(this.historicoSolcitudes);

      });
    }
    //maquina y estado
    if (this.inicio == null && this.termino == null && this.selectedItems.length >= 1 && this.estado != null) {
      console.log('maquina y estado')


      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;

          this.selectedItems.forEach(itemMaquina => {
            if (item.ItemCode == itemMaquina.item_id) {
              if (item.status_Name == "Pendiente") {
                this.serviciosPendientes.push(item);
              }
              else if (item.status_Name == "Abierto") {
                this.serviciosEnCurso.push(item);
              }
              else {
                var abierto = false;
              }

              item.estado = abierto;
              if (this.estado == true || this.estado == false) {
                if (item.estado == this.estado) {
                  this.historicoSolcitudes.push(item)
                }
              } else if (this.estado == "TODO") {
                this.historicoSolcitudes.push(item)
              }
            }
          })

        });

        console.log(this.historicoSolcitudes);

      });
    }
    //solo estado
    if (this.inicio == null && this.termino == null && this.selectedItems.length == 0 && this.estado != null) {
      console.log('solo estado')
      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      //////////////////////////////////
      ///////////////////////////////////
      this._api.ObtieneServiciosTodos().subscribe(res => {



        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;


          if (item.status_Name == "Pendiente") {
            this.serviciosPendientes.push(item);
          }
          else if (item.status_Name == "Abierto") {
            this.serviciosEnCurso.push(item);
          }
          else {
            var abierto = false;
          }

          item.estado = abierto;
          if (this.estado == true || this.estado == false) {
            if (item.estado == this.estado) {
              this.historicoSolcitudes.push(item)
            }
          } else if (this.estado == "TODO") {
            this.historicoSolcitudes.push(item)
          }


        });

        console.log(this.historicoSolcitudes);

      });
    }
    this.AbrirFiltro();

  }
  quitarfiltro() {
    if (this.inicio == null && this.termino == null && this.selectedItems.length == 0) {
      console.log("sin filtro anterior");
    } else {
      console.log("limpia y recarga datos original");
      this.inicio = null;
      this.termino = null;
      this.selectedItems = [];
      this.serviciosPendientes = [];
      this.serviciosEnCurso = [];
      this.historicoSolcitudes = [];
      this._api.ObtieneServiciosTodos().subscribe(res => {
        console.log(res)


        res.forEach(item => {

          if (!item.F_ASISTENCIA_TECNICA) {
            item.F_ASISTENCIA_TECNICA = item.F_SOLICITUD;
          }

          var abierto = true;

          if (item.status_Name == "Pendiente") {
            this.serviciosPendientes.push(item);
          }
          else if (item.status_Name == "Abierto") {
            this.serviciosEnCurso.push(item);
          }
          else {
            var abierto = false;
          }

          item.estado = abierto;
          this.historicoSolcitudes.push(item)
        });
        console.log(this.historicoSolcitudes);

      });
    }
    this.AbrirFiltro();

  }

}
