<div class="mostrar-footer" id="ver-footer" (click)="verFooter();" ><span class="flecha-icon icon-up-arrow"></span></div>

<footer class="block-footer">
    <div id="animation-block" class="animation-block content-icon-footer">
        <a class="link--footer" (click)="navegar('menu');">
            <h3 class="title-icon"><span class="icon-footer icon-home-01"></span>Inicio</h3>
        </a>
        <a class="link--footer" (click)="navegar('qr');">
            <h3 class="title-icon"><span class="icon-footer icon-qr-02"></span>Lector QR</h3>
        </a>
        <a class="link--footer" (click)="navegar('solicitudDeServicio');">
            <h3 class="title-icon"><span class="icon-footer icon-alert-03"></span>Solicitud Servicio</h3>
        </a>
        <a class="link--footer" (click)="navegar('cuentaPersonal');">
            <h3 class="title-icon"><span class="icon-footer icon-usuario"></span>Mi Cuenta</h3>
        </a>
        <a class="link--footer" (click)="cerrarSession();">
            <h3 class="title-icon"><span class="icon-footer icon-cerrar"></span>Cerrar Sesión</h3>
        </a>
    </div>
</footer>


