<div class="header-coti">
    <div class="container">
      <div class="content-block-header">
        <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
        <div class="wrapper-header-coti">
          <a class="remover-line" href="/menu">
            <div class="burger"><span class="class-home icon-home-01"></span></div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper--modelos">
      <div class="content-box-img-txt-izq" data-aos="fade-right" data-aos-delay="100">
          <img class="img-izq" src="{{objMaquina.url_image_code}}" alt="">
      </div>
      <div class="content-box-img-txt-der" data-aos="fade-left" data-aos-delay="300">
          <div class="content-txt-der">
              <ul>
                <li class="line-bottom"><span class="title-bold">Cliente:</span> {{objMaquina.cliente}}</li>
                  <li class="line-bottom"><span class="title-bold">Equipo:</span> {{objMaquina.dscription_code}}</li>
                  <li class="line-bottom"><span class="title-bold">MODELO:</span> {{objMaquina.model}}</li>
                  <li class="line-bottom"><span class="title-bold">SERIE:</span> {{objMaquina.serie}}</li>
             <!--      <li class="line-bottom"><span class="title-bold">FECHA DE INSTALACIÓN:</span> {{objMaquina.U_INDS_IT_ANIO}}</li> -->

                 <li class="line-bottom"><span class="title-bold">FECHA DE INSTALACIÓN:</span> {{objMaquina.U_INDS_IT_ANIO}}</li>
              </ul>
              <img class="svg-proveedor" src="{{objMaquina.url_brand_image}}" alt="">
          </div>
      </div>
  </div>

  <div class="content-tables">
      <div class="container col-md-7">

          <div *ngFor="let item of historico">
            <div  *ngFor="let item2 of item.activities">
                <div class="wrapper-tables" data-aos="fade-up" data-aos-delay="100">
                    <div class="table-izq">
                        <ul class="centrar-listas">
                            <li class="txt-fechas">{{item2.date| date:'yyyy-MM-dd'}}</li>
                        </ul>
                    </div>
                    <div class="table-der">
                        <ul class="centrar-listas" >
                            <li>{{item2.description}}
                                <span class="txt-number" (click)="AbrirPDF(item2.attachments)">N°{{item.number}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>


      </div>
      <a class="remover-line" (click)="volverHistorico();">
              <button class="btn-back-machine2"><img class="icon-back" src="/assets/img/back.svg" alt=""> Volver</button>
          </a>
  </div>


  <app-btn-circle></app-btn-circle>
