import { Component, OnInit } from '@angular/core';
import { ApiCallService } from '../api-call.service';

@Component({
  selector: 'app-oc',
  templateUrl: './oc.component.html',
  styleUrls: ['./oc.component.scss']
})
export class OcComponent implements OnInit {
public pedidos:any;
  constructor(private _api: ApiCallService) { }

  ngOnInit(): void {

    this._api.Pedidos('CN76172201').subscribe(res => {
      console.log(res);
      this.pedidos = res;
    })

  }

}
