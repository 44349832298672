<div class="header-coti">
    <div class="container">
      <div class="content-block-header">
        <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
        <div class="wrapper-header-coti">
          <a class="remover-line" href="/menu">
            <div class="burger"><span class="class-home icon-home-01"></span></div>
          </a>
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="fondo-hoja-pdf">
      <div class="wrapper-pdf" data-aos="fade-in" data-aos-delay="100">
        <iframe class="contenido-pdf" id="iframe" src="" frameborder="0">
        </iframe>


        <a class="remover-line" (click)="volverHistorico();">
          <div class="content-button-pdf">
            <button class="btn-back-machine">
            <img class="icon-back" src="/assets/img/back.svg" alt=""> Volver</button>
          </div>
        </a>

      </div>
  </div>


  <app-btn-circle></app-btn-circle>