import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { CuentaPersonalComponent } from './cuenta-personal/cuenta-personal.component';
import { QrComponent } from './qr/qr.component';
import { MisMaquinasComponent } from './mis-maquinas/mis-maquinas.component';
import { VerPdfComponent } from './ver-pdf/ver-pdf.component';
import { SolicitudDeServicioComponent } from './solicitud-de-servicio/solicitud-de-servicio.component';

import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { OcComponent } from './oc/oc.component';
import { LoginComponent } from './login/login.component';
import { CuentasClarasNGComponent } from './cuentas-claras-ng/cuentas-claras-ng.component';
import { BtnCircleComponent } from './btn-circle/btn-circle.component';
import { ZXingScannerModule } from 'angular-weblineindia-qrcode-scanner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HistoricoComponent} from './historico/historico.component'

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { CookieService } from 'ngx-cookie-service';
import { ChartsModule } from 'ng2-charts';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const dbConfig: DBConfig  = {
  name: 'PlataformaServicio',
  version: 2.1,
  objectStoresMeta: [
    {
        store: 'Parametros',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [ ]
    }
]
};



@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    CuentaPersonalComponent,
    QrComponent,
    MisMaquinasComponent,
    VerPdfComponent,
    SolicitudDeServicioComponent,
    CotizacionesComponent,
    OcComponent,
    LoginComponent,
    CuentasClarasNGComponent,
    HistoricoComponent,
    BtnCircleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ZXingScannerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ChartsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    HttpClientModule,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
