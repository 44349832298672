<div class="block-gris">
    <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
</div>


<div class="fondo-qr">  
    <div class="border-qr">
        <img class="img-blink" src="/assets/img/blink-qr.svg" alt="">
        <!--<div class="component-camara">
            <span _ngcontent-oin-c17="" class="margen-icons-cam iconos2 icon-cuentas"></span>
            <span _ngcontent-gwd-c17="" class="margen-icons-cam iconos3 icon-pedidos"></span>
            <span _ngcontent-gwd-c17="" class="margen-icons-cam iconos4 icon-maquinas"></span>
            <span _ngcontent-gwd-c17="" class="margen-icons-cam iconos5 icon-servicios"></span>
        </div>-->
        <zxing-scanner [formats]="this.allowedFormats"
        [(device)]="dispositivo"  (camerasNotFound)="camerasNotFoundHandler($event)"
        (torchCompatible)="onTorchCompatible($event)"
        (camerasFound)="camerasFoundHandler($event)"   (scanSuccess)="scanSuccessHandler($event)">
        </zxing-scanner>
    </div>
    <!--<a href="/historico">
        <div class="content-qr" data-aos="fade-in" data-aos-delay="100">
            <img class="img-qr" src="/assets/img/qr.svg" alt="">
            <img class="img-blink" src="/assets/img/blink-qr.svg" alt="">
        </div>
    </a>-->
</div>



<app-btn-circle></app-btn-circle>