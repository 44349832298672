<div class="header-coti" data-aos="fade-in" data-aos-delay="100">
  <div class="container">
    <div class="content-block-header" data-aos="fade-in" data-aos-delay="200">
      <img class="log-cuenta" src="/assets/img/extendido.svg" alt="">
      <div class="wrapper-header-coti">
        <a class="remover-line" href="/menu">
          <div class="burger"><span class="class-home icon-home-01"></span></div>
        </a>
        <div class="btn-view-filters" (click)="AbrirFiltro();">Filtros</div>

        <div class="menu" id="menu" (click)="AbreMenu();">
          <div class="linea-1"></div>
          <div class="linea-2"></div>
          <div class="linea-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="panel-left-emergent">
  <div class="wrapper-list-left">
    <div id="left-animation" class="wrapper--fechas">
      <label class="color-title-fecha" for="start">
        <span class="margen-fech icon-calendar"></span> Fecha Inicio</label>
      <input id="ver-fecha" type="date" [(ngModel)]="inicio">

      <label class="color-title-fecha" for="start">
        <span class="margen-fech icon-calendar"></span> Fecha Final</label>
      <input id="ver-fecha" type="date" [(ngModel)]="termino">

      <!-- Seleccion multiple -->
      <h4 class="title--estados">MÁQUINAS</h4>
      <div class="wrapper-selection-multiply">
        <ng-multiselect-dropdown [placeholder]="'Seleccionar máquinas'" [settings]="dropdownSettings"
          [data]="dropdownList" [(ngModel)]="selectedItems">
        </ng-multiselect-dropdown>
      </div>


      <!--       <h4 class="title--estados">ESTADO</h4>
      <div class="select-box">
        <div class="select-box__current" tabindex="1">
          <div class="select-box__value">
            <input class="select-box__input" type="radio" id="0" value="1" name="Ben" checked="checked" />
            <p class="select-box__input-text">SI</p>
          </div>
          <div class="select-box__value">
            <input class="select-box__input" type="radio" id="1" value="2" name="Ben" />
            <p class="select-box__input-text">NO</p>
          </div>
          <div class="select-box__value">
            <input class="select-box__input" type="radio" id="2" value="3" name="Ben" />
            <p class="select-box__input-text">TODOS</p>
          </div>
          <img class="select-box__icon" src="/assets/img/down.svg" alt="Arrow Icon" aria-hidden="true" />
        </div>
        <ul class="select-box__list">
          <li>
            <label class="select-box__option" for="0" aria-hidden="aria-hidden">SI</label>
          </li>
          <li>
            <label class="select-box__option" for="1" aria-hidden="aria-hidden">NO</label>
          </li>
          <li>
            <label class="select-box__option" for="2" aria-hidden="aria-hidden">TODOS</label>
          </li>
        </ul>
      </div> -->
      <div class="wrapper-selection-multiply text-right">
        <div class="btn-view-filters" (click)="filtro();">Filtrar</div>
        <div class="btn-view-filters-red" (click)="quitarfiltro();">Quitar Filtro</div>
      </div>
    </div>
  </div>
</div>


<div class="wrapper-mask">
  <div class="panel-right-emergent">
    <div class="wrapper-list-right">
      <ul id="ver-animation" class="list-right">
        <!--opcion cotizacion  -->
        <a class="txt-hover" (click)="cambioElemento('cotizacion');">
          <li class="li-list">
            <span class="paint1 icon-derecha icon-pedidos"></span>Cotizaciones
          </li>
        </a>
          <!--opcion cotizacion Historicas  -->
        <a class="txt-hover" (click)="cambioElemento('cotizacionHistoricas');">
          <li class="li-list">
            <span class="paint4 icon-derecha icon-pedidos"></span>Cotizaciones Historicas
          </li>
        </a>
        <!--opcion Órdenes de compra  -->
        <a class="txt-hover" (click)="cambioElemento('nv');">
          <li class="li-list">
            <span class="paint2 icon-derecha icon-orden"></span>Órdenes de compra o en uso
          </li>
        </a>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="this.paginaActual=='cotizacion'">

  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color1">COTIZACIONES</h1>
    </div>
    <div class="container scroll-eje-x">
      <table class="table ancho-mobile">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Cotización</th>
            <!-- <th scope="col">Máquina</th>
                <th scope="col">Serie</th> -->
            <th scope="col">Fecha de cotización</th>
            <!-- <th scope="col">Vigente</th> -->
            <!-- <th scope="col">Comentario</th> -->
            <th class="alinear-derecha" scope="col">Monto Cotización</th>
            <!-- <th scope="col">Acepta Cotización</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of cotizaciones; let i=index">
            <td scope="row" class="link-element" (click)="AbrirAdjunto(item);">{{item.DocNum}}</td>
            <!-- <td  class="link-element" (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td>
                <td  class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
            <td>{{item.DocDueDate}}</td>
            <!--      <td>{{item.vigente}}</td> -->
            <!-- <td><span class="btn-ver"  (click)="verObservaciones(item.Comments);">VER</span></td> -->
            <td align="right">{{item.totalCLP}}</td>
            <!--                 <td>
                  <div>
                    <label class="switch font-20">
                      <input type="checkbox" [(ngModel)]="item.U_INDS_PS_COTI_ACEPTADA" (change)="AceptarCotizacion(i,item)">
                      <span class="slider"></span>
                    </label>
                  </div>
              </td> -->
          </tr>

        </tbody>
      </table>
    </div>
  </div>

</div>

<div *ngIf="this.paginaActual=='cotizacionHistoricas'">

  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color4">HISTÓRICO DE COTIZACIONES</h1>
    </div>
    <div class="container scroll-eje-x">
        <table class="table ancho-mobile">
            <thead class="posicion-top">
              <tr>
                <th scope="col">Cotización</th>
                <!-- <th scope="col">Máquina</th>
                <th scope="col">Serie</th> -->
                <th scope="col">Fecha de cotización</th>
           <!--      <th scope="col">Vigente</th> -->
                <!-- <th scope="col">Comentario</th> -->
                <th class="alinear-derecha" scope="col">Monto Cotización</th>
        <!--         <th scope="col">Acepta Cotización</th> -->
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let item of cotizacionesHistoricas; let i=index">
                <td scope="row" class="link-element"  (click)="AbrirAdjunto(item);">{{item.DocNum}}</td>
                <!-- <td  class="link-element" (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td>
                <td  class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
                <td>{{item.DocDueDate}}</td>
               <!--  <td>{{item.vigente}}</td> -->
                <!-- <td><span class="btn-ver"  (click)="verObservaciones(item.Comments);">VER</span></td> -->
                <td align="right">{{item.totalCLP}}</td>
                <!-- <td>
                  <div>
                    <label class="switch font-20">
                      <input type="checkbox" [(ngModel)]="item.U_INDS_PS_COTI_ACEPTADA" (change)="otro(i,item)">
                      <span class="slider"></span>
                    </label>
                  </div>
              </td> -->
          </tr>

        </tbody>
      </table>
    </div>
  </div>

</div>

<div *ngIf="this.paginaActual=='nv'">
  <div class="contenedor-tabla" data-aos="fade-up" data-aos-delay="100">
    <div class="wrapper-titles" data-aos="fade-up" data-aos-delay="100">
      <h1 class="title-start-color2">OC PENDIENTES O EN CURSO</h1>
    </div>
    <div class="container scroll-eje-x">
      <table class="table ancho-mobile">
        <thead class="posicion-top">
          <tr>
            <th scope="col">Orden de compra</th>
            <!-- <th scope="col">Máquina</th>
              <th scope="col">Serie</th> -->
            <th scope="col">Fecha de pedido</th>
            <!-- <th scope="col">Comentario</th> -->
            <th class="alinear-derecha" scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of nv; let i=index">
            <td class="link-element" scope="row" (click)="AbrirAdjunto(item);">{{item.NumAtCard}}</td>
            <!-- <td class="link-element" (click)="AbrirPDFMaquina(item);">{{item.Maquina_descripcion}}</td>
              <td class="link-element" (click)="AbrirHistorico(item);">{{item.Serie}}</td> -->
            <td>{{item.U_FE_FECHADB2.substring(0,10)}}</td>
            <!-- <td><span class="btn-ver"  (click)="verObservaciones(item.Comments);">VER</span></td> -->
            <td align="right">{{item.totalCLP}}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</div>


<app-btn-circle></app-btn-circle>
